import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { ASSET_TYPES, ASSET_POSITIONS, MULTI_ASSET_SPACING, shouldConnect } from './connectionRules';
import SettingsIcon from '@mui/icons-material/Settings'; // Import the default controller icon
import Typography from '@mui/material/Typography';

const AssetNode = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: 120,
  position: 'relative',
  border: `1px solid ${theme.palette.divider}`,
  ...(theme.palette.mode === 'dark' && {
    border: `2px solid ${theme.palette.grey[700]}`,
    boxShadow: `0 0 10px 0 ${theme.palette.grey[900]}`,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 15px 0 ${theme.palette.grey[800]}`,
    },
  }),
  transition: 'all 0.2s ease-in-out',
}));

const getFlowDirection = (startType, endType) => {
  // PV connection should always flow TO controller
  if ((startType === ASSET_TYPES.PV && endType === ASSET_TYPES.CONTROLLER) ||
      (endType === ASSET_TYPES.PV && startType === ASSET_TYPES.CONTROLLER)) {
    // Return 'reverse' if PV is the end point
    return endType === ASSET_TYPES.PV ? 'reverse' : 'forward';
  }
  
  // Controller to load is one way
  if (startType === ASSET_TYPES.CONTROLLER && endType === ASSET_TYPES.LOAD) {
    return 'forward';
  }
  
  // Battery and Tariff connections are bidirectional
  if ((startType === ASSET_TYPES.BATTERY || startType === ASSET_TYPES.TARIFF) &&
      endType === ASSET_TYPES.CONTROLLER) {
    return 'bidirectional';
  }
  if (startType === ASSET_TYPES.CONTROLLER && 
      (endType === ASSET_TYPES.BATTERY || endType === ASSET_TYPES.TARIFF)) {
    return 'bidirectional';
  }
  return 'forward'; // default fallback
};

const Connection = ({ start, end, startType, endType }) => {
  const flowDirection = getFlowDirection(startType, endType);

  // Define the path based on direction
  const pathDef = flowDirection === 'reverse'
    ? `M ${end.x},${end.y} Q ${(start.x + end.x) / 2},${(start.y + end.y) / 2} ${start.x},${start.y}`
    : `M ${start.x},${start.y} Q ${(start.x + end.x) / 2},${(start.y + end.y) / 2} ${end.x},${end.y}`;

  return (
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }}
    >
      {/* Background static path */}
      <motion.path
        d={pathDef}
        stroke="currentColor"
        strokeWidth={2}
        strokeOpacity={0.3}
        fill="none"
      />

      {/* Animated pulsing paths */}
      {flowDirection === 'bidirectional' ? (
        <>
          {/* Forward pulse */}
          <motion.path
            d={pathDef}
            stroke="currentColor"
            strokeWidth={2}
            fill="none"
            strokeDasharray="5,5"
            initial={{ pathLength: 0, strokeOpacity: 0.8 }}
            animate={{
              pathLength: [0, 1],
              strokeOpacity: [0.8, 0],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatDelay: 1.5,
              ease: "linear",
            }}
          />
          {/* Backward pulse */}
          <motion.path
            d={`M ${end.x},${end.y} Q ${(start.x + end.x) / 2},${(start.y + end.y) / 2} ${start.x},${start.y}`}
            stroke="currentColor"
            strokeWidth={2}
            fill="none"
            strokeDasharray="5,5"
            initial={{ pathLength: 0, strokeOpacity: 0.8 }}
            animate={{
              pathLength: [0, 1],
              strokeOpacity: [0.8, 0],
            }}
            transition={{
              duration: 1.5,
              delay: 1.5,
              repeat: Infinity,
              repeatDelay: 1.5,
              ease: "linear",
            }}
          />
        </>
      ) : (
        // One-way pulse
        <motion.path
          d={pathDef}
          stroke="currentColor"
          strokeWidth={2}
          fill="none"
          strokeDasharray="5,5"
          initial={{ pathLength: 0, strokeOpacity: 0.8 }}
          animate={{
            pathLength: [0, 1],
            strokeOpacity: [0.8, 0],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "linear",
          }}
        />
      )}
    </svg>
  );
};

Connection.propTypes = {
  start: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  end: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  startType: PropTypes.string.isRequired,
  endType: PropTypes.string.isRequired,
};

const assetShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  selectedInstance: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
});

export default function TopologyVisualization({ placedAssets }) {
  const boxRef = React.useRef(null);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    if (boxRef.current) {
      const updateDimensions = () => {
        setDimensions({
          width: boxRef.current.offsetWidth,
          height: boxRef.current.offsetHeight
        });
      };

      updateDimensions();
      window.addEventListener('resize', updateDimensions);

      return () => window.removeEventListener('resize', updateDimensions);
    }
  }, []);

  const calculateNodePositions = (assets) => {
    if (!dimensions.width || !dimensions.height || assets.length === 0) return [];
    
    const centerX = dimensions.width / 2;
    const centerY = dimensions.height / 2;

    // Group assets by type
    const assetsByType = assets.reduce((acc, asset) => {
      if (ASSET_POSITIONS[asset.type]) {
        acc[asset.type] = acc[asset.type] || [];
        acc[asset.type].push(asset);
      }
      return acc;
    }, {});

    // Create default controller if there are assets but no controller
    const defaultController = {
      id: 'default-controller',
      type: ASSET_TYPES.CONTROLLER,
      name: 'Controller',
      icon: <SettingsIcon color="primary" />,
    };

    // Calculate positions for all assets including the controller
    const positionedAssets = [];

    // Add controller first (either default or existing) only if there are assets
    if (assets.length > 0) {
      const controller = assetsByType[ASSET_TYPES.CONTROLLER]?.[0] || defaultController;
      positionedAssets.push({
        ...controller,
        x: centerX + ASSET_POSITIONS[ASSET_TYPES.CONTROLLER].x,
        y: centerY + ASSET_POSITIONS[ASSET_TYPES.CONTROLLER].y,
      });
    }

    // Add other assets
    Object.entries(assetsByType).forEach(([type, typeAssets]) => {
      if (type !== ASSET_TYPES.CONTROLLER && ASSET_POSITIONS[type]) {
        const basePosition = ASSET_POSITIONS[type];
        const totalWidth = (typeAssets.length - 1) * MULTI_ASSET_SPACING;
        const startX = -totalWidth / 2;

        typeAssets.forEach((asset, index) => {
          const offset = index * MULTI_ASSET_SPACING;
          positionedAssets.push({
            ...asset,
            x: centerX + basePosition.x + startX + offset,
            y: centerY + basePosition.y,
          });
        });
      }
    });

    return positionedAssets;
  };

  const nodes = calculateNodePositions(placedAssets);

  return (
    <Box
      ref={boxRef}
      sx={{
        width: '100%',
        height: 600,
        position: 'relative',
        overflow: 'hidden',
        bgcolor: 'background.default',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {dimensions.width > 0 && (
        <>
          {placedAssets.length === 0 ? (
            <Typography 
              color="text.secondary"
              variant="body1"
              sx={{ textAlign: 'center' }}
            >
              Add assets to visualize system topology
            </Typography>
          ) : (
            <>
              {/* Draw connections first */}
              {nodes.map((node, i) => 
                nodes.slice(i + 1).map((otherNode) => {
                  if (shouldConnect(node.type, otherNode.type)) {
                    return (
                      <Connection
                        key={`${node.id}-${otherNode.id}`}
                        start={{ x: node.x, y: node.y }}
                        end={{ x: otherNode.x, y: otherNode.y }}
                        startType={node.type}
                        endType={otherNode.type}
                      />
                    );
                  }
                  return null;
                }).filter(Boolean)
              )}

              {/* Draw nodes on top */}
              {nodes.map((node) => (
                <AssetNode
                  key={node.id}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  style={{
                    position: 'absolute',
                    left: node.x - 60,
                    top: node.y - 60,
                  }}
                >
                  {node.icon}
                  <span>{node.name}</span>
                  {node.selectedInstance && (
                    <span style={{ fontSize: '0.8em', color: 'text.secondary' }}>
                      {node.selectedInstance.name}
                    </span>
                  )}
                </AssetNode>
              ))}
            </>
          )}
        </>
      )}
    </Box>
  );
}

TopologyVisualization.propTypes = {
  placedAssets: PropTypes.arrayOf(assetShape).isRequired,
}; 