import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { GoogleIcon, FacebookIcon, SitemarkIcon } from '../../marketing-page/sign-in/CustomIcons';
import AppTheme from '../../shared-theme/AppTheme';
import { useAuth } from '../../../contexts/AuthContext';
import PropTypes from 'prop-types';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  boxShadow:
    '0px 5px 15px rgba(0, 0, 0, 0.1), 0px 15px 35px rgba(0, 0, 0, 0.05)',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.background.default,
    boxShadow:
      '0px 5px 15px rgba(0, 0, 0, 0.5), 0px 15px 35px rgba(0, 0, 0, 0.08)',
  }),
}));

export default function SignUp({ onClose = () => {}, redirectPath = '/intake' }) {
  const navigate = useNavigate();
  const { signup, login } = useAuth();
  const [formErrors, setFormErrors] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');
  const [formData, setFormData] = React.useState({
    name: '',
    password: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validateForm()) return;

    setIsSubmitting(true);
    setSubmitError('');

    const formData = new FormData(event.currentTarget);
    const credentials = {
      name: formData.get('name'),
      password: formData.get('password'),
    };

    try {
      // First signup
      const signupResponse = await signup(credentials);

      if (signupResponse.success) {
        // Then immediately login
        const loginResponse = await login({
          identifier: credentials.name,  // Using name as identifier
          password: credentials.password
        });

        if (loginResponse.success) {
          onClose();
          navigate(redirectPath || '/intake');
        } else {
          setSubmitError('Signup successful but login failed. Please try logging in manually.');
        }
      } else {
        setSubmitError(signupResponse.message);
      }
    } catch (error) {
      console.error('Signup error:', error);
      setSubmitError('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.name) {
      errors.name = 'Name is required';
      isValid = false;
    }

    if (!formData.password) {
      errors.password = 'Password is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Stack direction="column" justifyContent="center">
        <Card variant="outlined">
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <SitemarkIcon />
          </Box>
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Sign up
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="name">Name</FormLabel>
              <TextField
                error={!!formErrors.name}
                helperText={formErrors.name}
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange('name')}
                required
                fullWidth
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                error={!!formErrors.password}
                helperText={formErrors.password}
                id="password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange('password')}
                required
                fullWidth
              />
            </FormControl>

            {submitError && (
              <Typography 
                color="error" 
                variant="body2" 
                sx={{ textAlign: 'center' }}
              >
                {submitError}
              </Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Signing up...' : 'Sign up'}
            </Button>
          </Box>

          <Divider>or</Divider>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => alert('Sign up with Google')}
              startIcon={<GoogleIcon />}
            >
              Sign up with Google
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => alert('Sign up with Facebook')}
              startIcon={<FacebookIcon />}
            >
              Sign up with Facebook
            </Button>
          </Box>
        </Card>
      </Stack>
    </AppTheme>
  );
}

SignUp.propTypes = {
  onClose: PropTypes.func,
  redirectPath: PropTypes.string
}; 