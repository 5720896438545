// This component is used to display a card for an existing asset in the asset library.

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Stack,
  Box,
  Typography,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import { getAssetIcon } from './AssetCategories';
import { alpha } from '@mui/material/styles';

export default function AssetCard({
  asset,
  onEdit,
  onDelete,
  actions = true,
  renderAdditionalContent,
  sx = {},
  variant = "outlined",
  selected = false,
  showSelectionIndicator = false,
  onClick
}) {
  const theme = useTheme();
  
  // Add error handling for missing or malformed data
  if (!asset || !asset.type) {
    console.warn(`Invalid asset data:`, asset);
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography color="error">
            Invalid asset data
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const IconComponent = getAssetIcon(asset.type);
  if (!IconComponent) {
    console.warn(`No icon found for asset type: ${asset.type}`);
    return null;
  }

  // Determine if the card is interactive (clickable)
  const isInteractive = Boolean(onClick);

  // Combine default styles with provided sx prop
  const cardStyles = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...(isInteractive && {
      cursor: 'pointer',
      transition: 'all 0.2s',
      '&:hover': {
        bgcolor: selected ? 
          alpha(theme.palette.primary.light, 0.3) : 
          theme.palette.action.hover,
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      },
      ...(selected && {
        bgcolor: alpha(theme.palette.primary.light, 0.2),
        borderColor: 'primary.main',
      })
    }),
    ...sx
  };

  return (
    <Card 
      variant={variant} 
      sx={cardStyles}
      onClick={onClick}
    >
      <CardContent sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Stack spacing={2} sx={{ height: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <IconComponent color="primary" />
              <Typography variant="h6" component="div">
                {asset.name}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              {showSelectionIndicator && selected && (
                <CheckCircleIcon color="primary" sx={{ fontSize: 20 }} />
              )}
              {actions && (
                <Stack direction="row" spacing={1}>
                  {onEdit && (
                    <IconButton 
                      size="small" 
                      onClick={(e) => {
                        e.stopPropagation();
                        onEdit(asset);
                      }}
                      sx={{ color: 'primary.main' }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                  {onDelete && (
                    <IconButton 
                      size="small" 
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(asset);
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
              )}
            </Stack>
          </Box>

          {/* Specifications */}
          <Stack spacing={1} sx={{ flexGrow: 1 }}>
            {Object.entries(asset.specs || {}).map(([key, value]) => (
              <Box 
                key={key}
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  {key}:
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ color: 'text.primary' }}
                >
                  {value?.toString() || ''}
                </Typography>
              </Box>
            ))}
          </Stack>

          {renderAdditionalContent && renderAdditionalContent(asset)}
        </Stack>
      </CardContent>
    </Card>
  );
}

AssetCard.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    specs: PropTypes.objectOf(PropTypes.string).isRequired,
    params: PropTypes.object
  }).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  actions: PropTypes.bool,
  renderAdditionalContent: PropTypes.func,
  sx: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  variant: PropTypes.string,
  selected: PropTypes.bool,
  showSelectionIndicator: PropTypes.bool,
  onClick: PropTypes.func
};