import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';

// Drag type constant
export const ASSET_DRAG_TYPE = 'asset';

const DraggableAssetIcon = ({ type, config }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ASSET_DRAG_TYPE,
    item: { type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Box
      ref={drag}
      sx={(theme) => ({
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        p: 3,
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        backgroundColor: theme.palette.background.paper,
        transition: 'all 0.3s ease',
        '&:hover': {
          bgcolor: theme.palette.action.hover,
          transform: 'translateY(-3px)',
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
        },
      })}
    >
      <Box sx={(theme) => ({ 
        color: theme.palette.primary.main,
        transform: 'scale(1.2)'
      })}>
        {config.icon}
      </Box>
      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
        {config.name}
      </Typography>
    </Box>
  );
};

DraggableAssetIcon.propTypes = {
  type: PropTypes.string.isRequired,
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  }).isRequired,
};

export default DraggableAssetIcon; 