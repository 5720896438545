import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Slider } from '@mui/material';

export default function PeakHoursSlider({ startHour, endHour, onChange }) {
  const [value, setValue] = React.useState([startHour || 0, endHour || 23]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue[0], newValue[1]);
  };

  const formatHour = (hour) => {
    return `${String(hour).padStart(2, '0')}:00`;
  };

  const marks = [
    { value: 0, label: '00:00' },
    { value: 6, label: '06:00' },
    { value: 12, label: '12:00' },
    { value: 18, label: '18:00' },
    { value: 23, label: '23:00' }
  ];

  return (
    <Box sx={{ 
      '& .MuiTextField-root': { m: 1, width: '25ch' },
      p: '16.5px 14px'
    }}>
      <Typography variant="body1" color="rgba(0, 0, 0, 0.6)" sx={{ mb: 1 }}>
        Peak Hours Range
      </Typography>
      <Box sx={{ px: 1 }}>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          valueLabelFormat={formatHour}
          marks={marks}
          min={0}
          max={23}
          step={1}
          disableSwap
          sx={{
            '& .MuiSlider-valueLabel': {
              backgroundColor: 'primary.main'
            }
          }}
        />
      </Box>
    </Box>
  );
}

PeakHoursSlider.propTypes = {
  startHour: PropTypes.number,
  endHour: PropTypes.number,
  onChange: PropTypes.func.isRequired
}; 