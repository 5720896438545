// This file handles the UI presentation and class mapping.
// It defines how different energy assets should be categorized 
// and displayed in the UI.

import SolarPowerIcon from '@mui/icons-material/SolarPower';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PaidIcon from '@mui/icons-material/Paid';
import * as React from 'react';

// Define the asset classes that match the backend API
export const ASSET_CLASSES = {
  PV: 'pv',
  STORAGE: 'battery',
  LOAD: 'load',
  CONTROLLER: 'controller',
  TARIFF: 'tariff'
};

export const ASSET_CATEGORIES = [
  {
    id: ASSET_CLASSES.PV,
    name: 'PV System',
    icon: <SolarPowerIcon />,
    description: 'Solar panel configurations and systems'
  },
  {
    id: ASSET_CLASSES.STORAGE,
    name: 'Energy Storage',
    icon: <BatteryChargingFullIcon />,
    description: 'Battery storage systems'
  },
  {
    id: ASSET_CLASSES.LOAD,
    name: 'Load',
    icon: <HomeIcon />,
    description: 'Energy consumption profiles'
  },
  {
    id: ASSET_CLASSES.CONTROLLER,
    name: 'Controller',
    icon: <SettingsIcon />,
    description: 'Energy management and control systems'
  },
  {
    id: ASSET_CLASSES.TARIFF,
    name: 'Tariff',
    icon: <PaidIcon />,
    description: 'Energy pricing and tariff structures'
  }
];

// Add after the ASSET_CATEGORIES array

export const ASSET_TYPE_LABELS = {
  // PV Systems
  pv: 'PV System',
  pv_weather: 'Weather-based PV',
  pv_profile: 'Profile-based PV',
  
  // Storage
  battery: 'Battery Storage',
  
  // Controllers
  battery_buffer_controller: 'Battery Controller',
  pv_curtailment_controller: 'PV Controller',
  controller_base: 'Base Controller',
  controller_grid: 'Grid Controller',
  
  // Loads
  load: 'Load Profile',
  
  // Tariffs
  variable_tariff: 'Variable Tariff',
  fixed_tariff: 'Fixed Tariff',
  dynamic_tariff: 'Dynamic Tariff'
};


// Metric labels mapping used to fetch metrics from the backend
export const ASSET_METRICS_MAPPING = {
  // PV Systems
  pv: 'PVMetrics',
  pv_weather: 'PVMetrics',
  pv_profile: 'PVMetrics',
  
  // Storage
  battery: 'BatteryMetrics',
  
  // Controllers
  controller_base: 'ControllerMetrics',
  controller_grid: 'ControllerMetrics',
  controller_pvcurtailment: 'ControllerMetrics',
  
  // Loads
  load: 'LoadMetrics',
  
  // Tariffs
  tariff_variable: 'TariffMetrics',
  tariff_fixed: 'TariffMetrics', 
  tariff_dynamic: 'TariffMetrics'
};


// Static metrics configuration used 
export const STATIC_METRICS_CONFIG = {
  consumption: {
    title: 'Consumption Metrics',
    color: '#ff9800', // Orange
    metrics: {
      battery_to_load: { label: 'Battery to Load', type: 'energy', color: '#ffd54f' },
      grid_to_load: { label: 'Grid to Load', type: 'energy', color: '#ffb74d' },
      load_shed: { label: 'Load Shed', type: 'energy', color: '#ff7043' },
      total_load_energy: { label: 'Total Load Energy', type: 'energy', color: '#f57c00' }
    }
  },
  production: {
    title: 'Production Metrics',
    color: '#4caf50', // Green
    metrics: {
      pv_curtailed: { label: 'PV Curtailed', type: 'energy', color: '#81c784' },
      pv_to_battery: { label: 'PV to Battery', type: 'energy', color: '#66bb6a' },
      pv_to_grid: { label: 'PV to Grid', type: 'energy', color: '#4caf50' },
      pv_to_load: { label: 'PV to Load', type: 'energy', color: '#43a047' },
      total_pv_energy: { label: 'Total PV Energy', type: 'energy', color: '#388e3c' }
    }
  },
  storage: {
    title: 'Storage Metrics',
    color: '#2196f3', // Blue
    metrics: {
      battery_throughput: { label: 'Battery Throughput', type: 'energy', color: '#64b5f6' },
      battery_to_grid: { label: 'Battery to Grid', type: 'energy', color: '#42a5f5' },
      grid_to_battery: { label: 'Grid to Battery', type: 'energy', color: '#2196f3' },
      total_battery_charge: { label: 'Total Battery Charge', type: 'energy', color: '#1e88e5' },
      total_battery_discharge: { label: 'Total Battery Discharge', type: 'energy', color: '#1976d2' }
    }
  },
  financial: {
    title: 'Financial Metrics',
    color: '#9c27b0', // Purple
    metrics: {
      capex: { label: 'CAPEX', type: 'cost', color: '#ba68c8' },
      opex: { label: 'OPEX', type: 'cost', color: '#ab47bc' },
      lcoe: { label: 'LCOE', type: 'price', color: '#9c27b0' },
      net_energy_cost: { label: 'Net Energy Cost', type: 'cost', color: '#8e24aa' },
      total_export_revenue: { label: 'Export Revenue', type: 'cost', color: '#7b1fa2' },
      total_import_cost: { label: 'Import Cost', type: 'cost', color: '#6a1b9a' },
      payback_time: { label: 'Payback Time', type: 'years', color: '#4a148c' },
      roi: { label: 'ROI', type: 'percentage', color: '#4a148c' }
    }
  }
};

export const formatMetricValue = (value, type) => {
  if (typeof value !== 'number') return value;
  
  switch (type) {
    case 'power':
      return `${value.toFixed(2)} kW`;
    case 'energy':
      return `${value.toFixed(2)} kWh`;
    case 'price':
      return `${value.toFixed(4)} €/kWh`;
    case 'cost':
      return `${Math.round(value / 1000)} k€`;
    case 'percentage':
      return `${(value * 100).toFixed(1)}%`;
    default:
      return value.toFixed(2);
  }
};

// Helper function to get category config by ID
export const getAssetCategoryConfig = (categoryId) => 
  ASSET_CATEGORIES.find(category => category.id === categoryId);

// Helper function to get icon component for a specific asset type
export const getAssetIcon = (assetType) => {
  // Find matching category based on asset type prefix
  const category = ASSET_CATEGORIES.find(cat => {
    if (assetType.startsWith('pv')) return cat.id === ASSET_CLASSES.PV;
    if (assetType === 'battery') return cat.id === ASSET_CLASSES.STORAGE;
    if (assetType === 'load') return cat.id === ASSET_CLASSES.LOAD;
    if (assetType.startsWith('controller')) return cat.id === ASSET_CLASSES.CONTROLLER;
    if (assetType.startsWith('tariff')) return cat.id === ASSET_CLASSES.TARIFF;
    return false;
  });

  // Return the icon component from the category or null if not found
  return category ? category.icon.type : null;
};

export default ASSET_CATEGORIES; 