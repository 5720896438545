import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Slider } from '@mui/material';

export default function SOCSlider({ minSoc, maxSoc, onChange, label = "SOC Range" }) {
  const [value, setValue] = React.useState([minSoc || 0, maxSoc || 100]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue[0], newValue[1]);
  };

  const formatSoc = (soc) => {
    return `${soc}%`;
  };

  const marks = [
    { value: 0, label: '0%' },
    { value: 25, label: '25%' },
    { value: 50, label: '50%' },
    { value: 75, label: '75%' },
    { value: 100, label: '100%' }
  ];

  return (
    <Box sx={{ 
      '& .MuiTextField-root': { m: 1, width: '25ch' },
      p: '16.5px 14px'
    }}>
      <Typography variant="body1" color="rgba(0, 0, 0, 0.6)" sx={{ mb: 1 }}>
        {label}
      </Typography>
      <Box sx={{ px: 1 }}>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          valueLabelFormat={formatSoc}
          marks={marks}
          min={0}
          max={100}
          step={1}
          disableSwap
          sx={{
            '& .MuiSlider-valueLabel': {
              backgroundColor: 'primary.main'
            }
          }}
        />
      </Box>
    </Box>
  );
}

SOCSlider.propTypes = {
  minSoc: PropTypes.number,
  maxSoc: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string
}; 