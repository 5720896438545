import * as React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Stack, 
  Card, 
  CardContent,
  IconButton,
  CircularProgress,
  Collapse
} from '@mui/material';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { alpha } from '@mui/material/styles';

import { ASSET_DRAG_TYPE } from './DraggableAssetIcon';
import CreateAssetDialog from '../../../common-components/create-asset/CreateNewAssetDialog';
import AssetCard from '../../../common-components/existing-assets/AssetCard';

const AssetDropZone = ({ 
  onDrop, 
  assets,
  expandedAssets,
  handleAssetExpand,
  handleDeleteAsset,
  handleAssetParamChange,
  onAssetModelSelect,
  modelInstances,
  loadingInstances,
  onAssetCreated
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ASSET_DRAG_TYPE,
    drop: (item) => onDrop(item.type),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [selectedLibraryAsset, setSelectedLibraryAsset] = React.useState({});
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [creatingAssetType, setCreatingAssetType] = React.useState(null);

  // Update handleModelInstanceSelect
  const handleModelInstanceSelect = (assetId, instance) => {
    setSelectedLibraryAsset(prev => ({
      ...prev,
      [assetId]: instance.id
    }));
    
    const assetData = {
      id: instance.id,
      type: instance.type,
      params: instance.params || {}
    };

    onAssetModelSelect(assetId, assetData);
    
    if (instance.params) {
      Object.entries(instance.params).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          handleAssetParamChange(assetId, key, value.toString());
        }
      });
    }
    handleAssetExpand(assetId);
  };

  const handleNewAssetCreated = async (newAsset) => {
    console.log('New asset created:', newAsset);
    if (creatingAssetType) {
      // Call the parent handler to refresh model instances
      await onAssetCreated(creatingAssetType);
    }
    // Close dialog and reset state
    setCreateDialogOpen(false);
    setCreatingAssetType(null);
  };

  return (
    <Box
      ref={drop}
      sx={(theme) => ({
        height: '100%',
        minHeight: 400,
        border: '3px dashed',
        borderColor: isOver ? theme.palette.primary.main : theme.palette.divider,
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: isOver ? alpha(theme.palette.primary.main, 0.05) : theme.palette.background.default,
        transition: 'all 0.3s',
        p: 4,
        '&:hover': {
          borderColor: theme.palette.primary.main,
          bgcolor: alpha(theme.palette.primary.main, 0.02),
        },
      })}
    >
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Assemble your scenario
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {isOver ? 'Drop it here!' : 'Drag and drop assets here to build your scenario'}
        </Typography>
      </Box>

      <Stack spacing={2}>
        {assets.map((asset) => (
          <Card key={asset.id}>
            <CardContent>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {asset.icon}
                    <Stack>
                      <Typography>{asset.name}</Typography>
                      {selectedLibraryAsset[asset.id] && !expandedAssets[asset.id] && (
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{ mt: 0.5 }}
                        >
                          Selected Model: {modelInstances[asset.type]?.find(
                            instance => instance.id === selectedLibraryAsset[asset.id]
                          )?.name || ''}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      size="small"
                      onClick={() => handleAssetExpand(asset.id)}
                      color="primary"
                    >
                      <ExpandMoreIcon 
                        sx={{ 
                          transform: expandedAssets[asset.id] ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s'
                        }}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteAsset(asset.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Stack>

                <Collapse in={expandedAssets[asset.id]}>
                  <Stack spacing={2} sx={{ mt: 2, px: 2 }}>
                    {loadingInstances[asset.type] ? (
                      <CircularProgress />
                    ) : (
                      <Grid container spacing={2}>
                        {/* Create New Asset card */}
                        <Grid item xs={12} sm={6} md={4}>
                          <Card
                            variant="outlined"
                            sx={{
                              cursor: 'pointer',
                              height: '100%',
                              bgcolor: 'background.paper',
                              borderStyle: 'dashed',
                              borderWidth: 2,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              '&:hover': {
                                borderColor: 'primary.main',
                                bgcolor: 'action.hover',
                              },
                              transition: 'all 0.2s'
                            }}
                            onClick={() => {
                              setCreatingAssetType(asset.type);
                              setCreateDialogOpen(true);
                            }}
                          >
                            <CardContent>
                              <Stack
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ py: 1 }}
                              >
                                <AddIcon color="primary" sx={{ fontSize: 30 }} />
                                <Typography variant="subtitle1" color="primary">
                                  Create New {asset.name}
                                </Typography>
                              </Stack>
                            </CardContent>
                          </Card>
                        </Grid>

                        {/* Existing model instances */}
                        {modelInstances[asset.type]?.map((instance) => (
                          <Grid item xs={12} sm={6} md={4} key={instance.id}>
                            <AssetCard
                              asset={{
                                id: instance.id,
                                name: instance.name,
                                type: instance.type || asset.type,
                                specs: instance.specs || {},
                                params: instance.params || {}
                              }}
                              selected={selectedLibraryAsset[asset.id] === instance.id}
                              showSelectionIndicator={true}
                              onClick={() => handleModelInstanceSelect(asset.id, instance)}
                              actions={false}
                              sx={{
                                height: '100%'
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Stack>
                </Collapse>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <CreateAssetDialog
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
          setCreatingAssetType(null);
        }}
        onSubmit={handleNewAssetCreated}
        resetOnSuccess={true}
        category={{
          id: creatingAssetType?.toLowerCase() || '',
          name: assets.find(a => a.type === creatingAssetType)?.name || '',
          description: ''
        }}
      />
    </Box>
  );
};

AssetDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    params: PropTypes.object
  })).isRequired,
  expandedAssets: PropTypes.object.isRequired,
  handleAssetExpand: PropTypes.func.isRequired,
  handleDeleteAsset: PropTypes.func.isRequired,
  handleAssetParamChange: PropTypes.func.isRequired,
  onAssetModelSelect: PropTypes.func.isRequired,
  modelInstances: PropTypes.object.isRequired,
  loadingInstances: PropTypes.object.isRequired,
  onAssetCreated: PropTypes.func.isRequired,
};

export default AssetDropZone; 