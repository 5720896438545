// This widget is used to display the controller metrics for the simulation.

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import WidgetTimeseries from '../charts/WidgetTimeseries';
import WidgetDailyProfile from '../charts/WidgetDailyProfile';
import WidgetCandlestick from '../charts/WidgetCandlestick';
import simulationService from '../../../../../../../services/simulationService';

// Metric labels mapping for all controller types
const METRIC_LABELS = {
  // Battery-related metrics
  battery_to_grid: 'Battery Power to Grid',
  battery_to_load: 'Battery Power to Load',
  flow2b: 'Flow To Battery',
  
  // Grid-related metrics
  grid_to_battery: 'Grid Power to Battery',
  grid_to_load: 'Grid Power to Load',
  grid_violation: 'Grid Violations',
  p_grid: 'Power From Grid',
  
  // PV-related metrics
  pv_curtailed: 'PV Power Curtailed',
  pv_to_battery: 'PV Power to Battery',
  pv_to_grid: 'PV Power to Grid',
  pv_to_load: 'PV Power to Load',
  
  // Other metrics
  shed_load: 'Shed Load',
  soc: 'State of Charge'
};

const WidgetControllerMetrics = ({ simulationId, scenarioId, modelInstanceId, viewType }) => {
  const [availableMetrics, setAvailableMetrics] = React.useState([]);
  const [selectedMetric, setSelectedMetric] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  // Fetch available metrics when component mounts
  React.useEffect(() => {
    const fetchAvailableMetrics = async () => {
      try {
        const response = await simulationService.getAssetHourlyProfile(
          simulationId,
          scenarioId,
          modelInstanceId,
          'p_grid'
        );
        
        if (response.success) {
          const metrics = response.data.availableFields || [];
          setAvailableMetrics(metrics);
          const defaultMetric = metrics.includes('p_grid') ? 'p_grid' : metrics[0];
          setSelectedMetric(defaultMetric);
        }
      } catch (error) {
        console.error('Failed to fetch available metrics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableMetrics();
  }, [simulationId, scenarioId, modelInstanceId]);

  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  const renderGraph = () => {
    switch (viewType) {
      case 'timeseries':
        return (
          <WidgetTimeseries 
            simulationId={simulationId}
            scenarioId={scenarioId}
            modelInstanceId={modelInstanceId}
            metricKey={selectedMetric}
            title={`Yearly ${METRIC_LABELS[selectedMetric] || selectedMetric}`}
          />
        );
      case 'daily':
        return (
          <WidgetDailyProfile 
            simulationId={simulationId}
            scenarioId={scenarioId}
            modelInstanceId={modelInstanceId}
            metricKey={selectedMetric}
            title={`Daily Averaged ${METRIC_LABELS[selectedMetric] || selectedMetric}`}
          />
        );
      case 'monthly':
        return (
          <WidgetCandlestick 
            simulationId={simulationId}
            scenarioId={scenarioId}
            modelInstanceId={modelInstanceId}
            metricKey={selectedMetric}
            title={`Monthly Distribution of ${METRIC_LABELS[selectedMetric] || selectedMetric}`}
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Box sx={{ mt: 2 }}>
        <Typography>Loading metrics...</Typography>
      </Box>
    );
  }

  if (!selectedMetric) {
    return null;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <Select
            value={selectedMetric}
            onChange={handleMetricChange}
          >
            {availableMetrics.map((metric) => (
              <MenuItem key={metric} value={metric}>
                {METRIC_LABELS[metric] || metric}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {renderGraph()}
    </Box>
  );
};

WidgetControllerMetrics.propTypes = {
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modelInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  viewType: PropTypes.oneOf(['timeseries', 'daily', 'monthly']).isRequired
};

export default WidgetControllerMetrics; 