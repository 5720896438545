import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  Card, 
  CardActionArea, 
  CardContent, 
  Stack, 
  Typography 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { alpha } from '@mui/material/styles';

export default function CreateNewCard({ 
  onClick, 
  title, 
  subtitle,
  icon: CustomIcon = AddIcon,  // Allow custom icon with AddIcon as default
  fullHeight = true,           // Control card height
  dashedBorder = true,        // Control border style
  customContent,              // Allow custom content rendering
  sx = {}                     // Additional style overrides
}) {
  return (
    <Card 
      variant="outlined"
      sx={(theme) => ({
        height: fullHeight ? '100%' : 'auto',
        cursor: 'pointer',
        border: dashedBorder ? '2px dashed' : '1px solid',
        borderColor: dashedBorder ? theme.palette.primary.main : theme.palette.divider,
        bgcolor: 'transparent',
        '&:hover': {
          bgcolor: alpha(theme.palette.primary.main, 0.05),
          transform: 'translateY(-2px)',
          borderColor: theme.palette.primary.main,
        },
        transition: 'all 0.2s ease',
        ...sx
      })}
      onClick={onClick}
    >
      <CardActionArea sx={{ height: '100%' }}>
        <CardContent>
          {customContent ? (
            customContent
          ) : (
            <Stack 
              spacing={1} 
              alignItems="center" 
              justifyContent="center"
              sx={{ py: 2 }}
            >
              <CustomIcon color="primary" sx={{ fontSize: 32 }} />
              <Typography color="primary" variant="subtitle1">
                {title}
              </Typography>
              {subtitle && (
                <Typography color="text.secondary" variant="body2" align="center">
                  {subtitle}
                </Typography>
              )}
            </Stack>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CreateNewCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.elementType,
  fullHeight: PropTypes.bool,
  dashedBorder: PropTypes.bool,
  customContent: PropTypes.node,
  sx: PropTypes.object
}; 