// Custom date-time range picker only for the year.

import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';

// Enable UTC plugin
dayjs.extend(utc);

export default function WidgetDatePicker({ 
  label,
  value,
  onChange,
  views = ['year'],
  error,
  helperText
}) {
  const handleYearChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      onChange(newValue.year().toString());
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%' }}>
        <DatePicker
          label={label}
          views={views}
          value={dayjs(value)}
          onChange={handleYearChange}
          slotProps={{
            textField: { 
              size: 'small',
              fullWidth: true,
              error: error,
              helperText: helperText,
              sx: { 
                bgcolor: 'background.default',
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '&:hover': {
                    backgroundColor: 'action.hover',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                  },
                }
              }
            }
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}

WidgetDatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool,
  helperText: PropTypes.string
};
