// This component is the main grid that contains the simulation header and results view.

import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import WidgetSimulationHeader from '../components/header/WidgetSimulationHeader';
import WidgetResultsView from '../components/results/WidgetResultsView';
import Box from '@mui/material/Box';

export default function ExistingSimulationGrid({ simulationData }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <WidgetSimulationHeader 
            simulationName={simulationData.simulationName}
            status={simulationData.status}
          />
        </Grid>

        <Grid item xs={12}>
          <WidgetResultsView 
            status={simulationData.status}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

ExistingSimulationGrid.propTypes = {
  simulationData: PropTypes.shape({
    simulationName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    startedAt: PropTypes.string,
    completedAt: PropTypes.string,
    errorMessage: PropTypes.string
  }).isRequired
}; 