// This module is used to load the Google Maps API and ensure it is only loaded once.
// It is used in the WidgetCompanyBar component. 

import { GOOGLE_MAPS_API_KEY } from '../config/keys';

class GoogleMapsLoader {
  constructor() {
    this.isLoading = false;
    this.isLoaded = false;
    this.loadPromise = null;
    this.apiKey = GOOGLE_MAPS_API_KEY;
  }

  load() {
    // Check if API key is available
    if (!this.apiKey) {
      return Promise.reject(new Error('Google Maps API key is not configured'));
    }

    // Return existing promise if already loading
    if (this.loadPromise) {
      return this.loadPromise;
    }

    // Return immediately if already loaded
    if (this.isLoaded || window.google) {
      this.isLoaded = true;
      return Promise.resolve();
    }

    // Create new loading promise
    this.loadPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`;
      script.async = true;

      script.onload = () => {
        this.isLoaded = true;
        resolve();
      };

      script.onerror = (error) => {
        this.loadPromise = null;
        reject(new Error('Failed to load Google Maps API: ' + error.message));
      };

      document.head.appendChild(script);
    });

    return this.loadPromise;
  }
}

export default new GoogleMapsLoader(); 