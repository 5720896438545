import * as React from 'react';
import { 
  TextField, 
  Stack, 
  InputAdornment, 
  MenuItem 
} from '@mui/material';
import PeakHoursSlider from './sliders/PeakHoursSlider';

export default class TariffsAssetModule {
  static parameters = [
    { 
      name: 'type', 
      label: 'Tariff Type', 
      type: 'select', 
      required: true,
      options: [
        { value: 'tariff_fixed', label: 'Fixed' },
        { value: 'tariff_variable', label: 'Variable' },
        { value: 'tariff_dynamic', label: 'Dynamic' }
      ]
    },
    { 
      name: 'transport_cost', 
      label: 'Transport Cost', 
      unit: '€/kWh', 
      type: 'number', 
      required: true,
      min: 0
    }
  ];

  static additionalParameters = {
    tariff_fixed: [
      { 
        name: 'import_fixed_rate', 
        label: 'Import Fixed Rate', 
        unit: '€/kWh', 
        type: 'number', 
        required: true,
        min: 0
      },
      { 
        name: 'export_fixed_rate', 
        label: 'Export Fixed Rate', 
        unit: '€/kWh', 
        type: 'number', 
        required: false,
        defaultValue: 0,
        min: 0
      }
    ],
    tariff_variable: [
      { 
        name: 'import_peak_rate', 
        label: 'Import Peak Rate', 
        unit: '€/kWh', 
        type: 'number', 
        required: true,
        min: 0
      },
      { 
        name: 'import_off_peak_rate', 
        label: 'Import Off-Peak Rate', 
        unit: '€/kWh', 
        type: 'number', 
        required: true,
        min: 0
      },
      { 
        name: 'peak_start_hour', 
        label: 'Peak Start Hour', 
        unit: '', 
        type: 'number', 
        required: true,
        min: 0,
        max: 23
      },
      { 
        name: 'peak_end_hour', 
        label: 'Peak End Hour', 
        unit: '', 
        type: 'number', 
        required: true,
        min: 0,
        max: 23
      },
      { 
        name: 'export_peak_rate', 
        label: 'Export Peak Rate', 
        unit: '€/kWh', 
        type: 'number', 
        required: false,
        defaultValue: 0,
        min: 0
      },
      { 
        name: 'export_off_peak_rate', 
        label: 'Export Off-Peak Rate', 
        unit: '€/kWh', 
        type: 'number', 
        required: false,
        defaultValue: 0,
        min: 0
      }
    ],
    tariff_dynamic: [] // No additional parameters beyond transport_cost
  };

  static validate(data) {
    const errors = {};

    // Common validations
    if (!data.transport_cost || data.transport_cost < 0) {
      errors.transport_cost = 'Transport cost must be greater than or equal to 0';
    }

    if (data.type === 'tariff_fixed') {
      if (!data.import_fixed_rate || data.import_fixed_rate < 0) {
        errors.import_fixed_rate = 'Import fixed rate must be greater than or equal to 0';
      }
      if (data.export_fixed_rate !== undefined && data.export_fixed_rate < 0) {
        errors.export_fixed_rate = 'Export fixed rate must be greater than or equal to 0';
      }
    }

    if (data.type === 'tariff_variable') {
      if (!data.import_peak_rate || data.import_peak_rate < 0) {
        errors.import_peak_rate = 'Import peak rate must be greater than or equal to 0';
      }
      if (!data.import_off_peak_rate || data.import_off_peak_rate < 0) {
        errors.import_off_peak_rate = 'Import off-peak rate must be greater than or equal to 0';
      }
      if (!data.peak_start_hour || data.peak_start_hour < 0 || data.peak_start_hour > 23) {
        errors.peak_start_hour = 'Peak start hour must be between 0 and 23';
      }
      if (!data.peak_end_hour || data.peak_end_hour < 0 || data.peak_end_hour > 23) {
        errors.peak_end_hour = 'Peak end hour must be between 0 and 23';
      }
      if (data.export_peak_rate !== undefined && data.export_peak_rate < 0) {
        errors.export_peak_rate = 'Export peak rate must be greater than or equal to 0';
      }
      if (data.export_off_peak_rate !== undefined && data.export_off_peak_rate < 0) {
        errors.export_off_peak_rate = 'Export off-peak rate must be greater than or equal to 0';
      }
    }

    return errors;
  }

  static transformForAPI(data) {
    const baseData = {
      type: data.type,
      transport_cost: parseFloat(data.transport_cost)
    };

    switch (data.type) {
      case 'tariff_fixed':
        return {
          ...baseData,
          import_fixed_rate: parseFloat(data.import_fixed_rate),
          export_fixed_rate: data.export_fixed_rate !== undefined ? parseFloat(data.export_fixed_rate) : 0
        };
      case 'tariff_variable':
        return {
          ...baseData,
          import_peak_rate: parseFloat(data.import_peak_rate),
          import_off_peak_rate: parseFloat(data.import_off_peak_rate),
          peak_start_hour: parseInt(data.peak_start_hour),
          peak_end_hour: parseInt(data.peak_end_hour),
          export_peak_rate: data.export_peak_rate !== undefined ? parseFloat(data.export_peak_rate) : 0,
          export_off_peak_rate: data.export_off_peak_rate !== undefined ? parseFloat(data.export_off_peak_rate) : 0
        };
      default:
        return baseData;
    }
  }

  static renderFields({ data, onChange, errors }) {
    const getNumericValue = (value) => {
      if (!value && value !== 0) {
        return '';
      }
      const numValue = parseFloat(value);
      return isNaN(numValue) ? '' : numValue;
    };

    const currentType = data.type || '';
    const additionalParams = this.additionalParameters[currentType] || [];
    const allParams = [this.parameters[1], ...additionalParams]; // Include transport_cost

    const handlePeakHoursChange = (start, end) => {
      onChange('peak_start_hour', start);
      onChange('peak_end_hour', end);
    };

    return (
      <Stack spacing={2}>
        <TextField
          select
          label="Tariff Type"
          value={currentType}
          onChange={(e) => onChange('type', e.target.value)}
          required
        >
          {this.parameters[0].options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {currentType === 'tariff_variable' && (
          <PeakHoursSlider
            startHour={data.peak_start_hour}
            endHour={data.peak_end_hour}
            onChange={handlePeakHoursChange}
          />
        )}

        {allParams
          .filter(param => !['peak_start_hour', 'peak_end_hour'].includes(param.name))
          .map((param) => (
            <TextField
              key={param.name}
              label={param.label}
              type="number"
              value={getNumericValue(data[param.name])}
              onChange={(e) => {
                const newValue = e.target.value;
                onChange(param.name, newValue === '' ? undefined : newValue);
              }}
              error={!!errors?.[param.name]}
              helperText={errors?.[param.name] || (param.defaultValue !== undefined && !data[param.name] ? 
                `Default: ${param.defaultValue}` : undefined)}
              InputProps={{
                endAdornment: param.unit && (
                  <InputAdornment position="end">
                    {param.unit}
                  </InputAdornment>
                )
              }}
              inputProps={{
                min: param.min,
                max: param.max,
                step: "any"
              }}
              required={param.required}
            />
          ))}
      </Stack>
    );
  }
} 