// Custom date-time range picker component for the dashboard

import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';

// Enable UTC plugin
dayjs.extend(utc);

export default function CustomDateTimePicker({ 
  onDateRangeChange,
  initialStartDate = dayjs()
}) {
  const [selectedYear, setSelectedYear] = React.useState(initialStartDate);

  const handleYearChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      setSelectedYear(newValue);
      
      // Create UTC dates to avoid timezone issues
      const startDate = dayjs.utc(`${newValue.year()}-01-01 00:00:00`);
      const endDate = dayjs.utc(`${newValue.year()}-12-31 22:00:00`);
      
      onDateRangeChange?.({ 
        startDate,
        endDate
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%' }}>
        <DatePicker
          views={['year']}
          value={selectedYear}
          onChange={handleYearChange}
          slotProps={{
            textField: { 
              size: 'small',
              fullWidth: true,
              sx: { 
                bgcolor: 'background.default',
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '&:hover': {
                    backgroundColor: 'action.hover',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                  },
                }
              }
            }
          }}
        />
      </Box>
    </LocalizationProvider>
  );
}

CustomDateTimePicker.propTypes = {
  onDateRangeChange: PropTypes.func,
  initialStartDate: PropTypes.object
};