// This page displays a loading animation while the data is being collected.  

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, keyframes } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import AppTheme from '../../shared-theme/AppTheme';
import ColorModeIconDropdown from '../../shared-theme/ColorModeIconDropdown';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const fade = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

const StyledLogo = styled('img')({
  width: '300px',
  height: 'auto',
  filter: 'none',
  opacity: '1',
  animation: `
    ${pulse} 2s ease-in-out infinite,
    ${fade} 2s ease-in-out infinite
  `,
  willChange: 'transform, opacity',
});

export default function LoadingDataCollection() {
  const navigate = useNavigate();
  const [progress, setProgress] = React.useState(0);
  const logoPath = '/images/GeneralImages/vems_logo_dark.png';

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          setTimeout(() => {
            navigate('/collected-data');
          }, 500);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 250);

    return () => {
      clearInterval(timer);
    };
  }, [navigate]);

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Box
        sx={(theme) => ({
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
          ...theme.applyStyles('dark', {
            backgroundImage:
              'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
          }),
        })}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            zIndex: 1000
          }}
        >
          <ColorModeIconDropdown />
        </Box>

        <Container maxWidth="sm">
          <Box
            sx={{
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '2.5rem',
              paddingBottom: '2rem',
              transform: 'translateY(-13.25%)',
            }}
          >
            <StyledLogo
              src={logoPath}
              alt="VEMS Logo"
            />
            
            <Box sx={{ width: '80%', maxWidth: 400, textAlign: 'center' }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  mb: 2,
                  color: 'text.secondary',
                  fontSize: '1.1rem',
                  fontWeight: 500
                }}
              >
                Automatic data collection ...
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={progress} 
                sx={{
                  height: 8,
                  borderRadius: 4,
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 4,
                  }
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </AppTheme>
  );
}
