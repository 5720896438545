import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import BalanceIcon from '@mui/icons-material/Balance';
import WidgetSimulationTable from './WidgetSimulationTable';

const SimulatorGrid = React.memo(() => {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      {/* Title Section */}
      <Box sx={{ mb: 6, textAlign: 'left' }}>
        <Stack 
          direction="row" 
          alignItems="center" 
          justifyContent="flex-start"
          spacing={4}
          sx={{ mb: 3 }}
        >
          <Typography 
            variant="h1" 
            component="h1"
            sx={{ 
              fontSize: { xs: '3rem', sm: '4rem', md: '5rem' },
              fontWeight: 700,
              letterSpacing: '-0.02em'
            }}
          >
            Meet Libra
          </Typography>
          <BalanceIcon sx={{ 
            fontSize: { xs: 80, sm: 100, md: 120 },
            color: 'primary.main' 
          }} />
        </Stack>
        <Typography 
          variant="h4" 
          color="text.secondary"
          sx={{ 
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            maxWidth: '1200px',
            marginLeft: 0,
            fontWeight: 500,
            pl: 0,
            textAlign: 'left'
          }}
        >
          Your guide to balanced energy usage.
        </Typography>
      </Box>

      {/* Simulation Table Section */}
      <Box sx={{ mt: 4 }}>
        <WidgetSimulationTable />
      </Box>
    </Box>
  );
});

SimulatorGrid.displayName = 'SimulatorGrid';
export default SimulatorGrid;