// Side menu of the user interface for desktop

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuContent from './MenuContent';
import OptionsMenu from './OptionsMenu';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from '@mui/material/Tooltip';

const drawerWidth = 240;
const collapsedWidth = 72;

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: open ? drawerWidth : collapsedWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [`& .${drawerClasses.paper}`]: {
    width: open ? drawerWidth : collapsedWidth,
    boxSizing: 'border-box',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
}));

export default function SideMenu() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(() => {
    // Initialize from localStorage, default to true if not set
    const savedState = localStorage.getItem('sideMenuOpen');
    return savedState === null ? true : savedState === 'true';
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const vemsLogoName = theme.palette.mode === 'light' 
    ? '/images/GeneralImages/VEMS_logo_name_dark.svg' 
    : '/images/GeneralImages/VEMS_logo_name_white.svg';

  const handleDrawerToggle = () => {
    const newState = !open;
    setOpen(newState);
    localStorage.setItem('sideMenuOpen', newState.toString());
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleAvatarClick = (event) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Box
        sx={{
          p: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.palette.divider}`,
          minHeight: 50,
        }}
      >
        <Button 
          onClick={handleLogoClick} 
          sx={{ 
            p: 0,
            width: open ? '100%' : 'auto',
            justifyContent: 'flex-start',
            minHeight: 48,
            opacity: open ? 1 : 0,
            visibility: open ? 'visible' : 'hidden',
            transition: theme.transitions.create(['opacity', 'visibility'], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <img
            src={vemsLogoName}
            alt="VEMS Logo"
            style={{
              width: 'auto',
              height: '50px',
              maxWidth: '150px',
            }}
          />
        </Button>
        <IconButton 
          onClick={handleDrawerToggle}
          sx={{
            position: open ? 'relative' : 'absolute',
            right: open ? 'auto' : '12px',
            top: open ? 'auto' : '12px',
          }}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
      <Divider />
      <MenuContent collapsed={!open} />
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
          justifyContent: open ? 'flex-start' : 'center',
        }}
      >
        <Tooltip title={!open ? "Options" : ""}>
          <Avatar
            sizes="small"
            alt="Tess Verde"
            src="/images/UserInterfaceImages/tess_profile.jpeg"
            onClick={handleAvatarClick}
            sx={{ 
              width: 36, 
              height: 36,
              cursor: !open ? 'pointer' : 'default'
            }}
          />
        </Tooltip>
        {open && (
          <>
            <Box sx={{ mr: 'auto' }}>
              <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
                Tess Green
              </Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                tess@enterprise.com
              </Typography>
            </Box>
            <OptionsMenu />
          </>
        )}
        {!open && (
          <OptionsMenu 
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(anchorEl)}
            hideButton
          />
        )}
      </Stack>
    </Drawer>
  );
}
