// This widget is used to display the static results for the simulation
// It is used within the AssetResultsCard component

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import simulationService from '../../../../../../services/simulationService';
import { STATIC_METRICS_CONFIG, formatMetricValue } from '../../../../common-components/existing-assets/AssetCategories';
import WidgetSankey from './charts/WidgetSankey';
import WidgetCashflow from './charts/WidgetCashflow';
import WidgetExistingScenarioTopology from '../../../../common-components/scenario-topology/WidgetExistingScenarioTopology';

const DETAIL_VIEW_OPTIONS = {
  TIMESERIES: 'timeseries',
  DAILY: 'daily',
  MONTHLY: 'monthly'
};

const WidgetStaticResults = ({ 
  simulationId, 
  scenarioId, 
  scenarioName, 
  assets, 
  selectedAssetId, 
  onAssetSelect,
  selectedAsset,
  MetricsComponent
}) => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [view, setView] = React.useState('economics');
  const [detailView, setDetailView] = React.useState(DETAIL_VIEW_OPTIONS.TIMESERIES);

  React.useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await simulationService.getScenarioResults(simulationId, scenarioId);
        if (response.success) {
          setData(response.data);
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [simulationId, scenarioId]);

  // Helper function to check if a metrics section has data
  const hasMetricsData = (sectionKey, data) => {
    if (!data) return false;
    const metricsSection = data[`${sectionKey}_metrics`];
    return metricsSection && Object.values(metricsSection).some(value => value !== null);
  };

  const renderMetricsSection = (sectionKey, data) => {
    const section = STATIC_METRICS_CONFIG[sectionKey];
    const metricsData = data[`${sectionKey}_metrics`];
    const validMetrics = Object.entries(section.metrics).filter(([key]) => {
      return metricsData[key] !== null && 
             key !== 'total_import_cost' && 
             key !== 'total_export_revenue';
    });
    const gridSize = 12 / validMetrics.length;

    return (
      <Box>
        <Grid container spacing={2}>
          {validMetrics.map(([key, { label, type }]) => (
            <Grid item xs={12} sm={gridSize} key={key}>
              <Box sx={{ p: 1 }}>
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                  {label}
                </Typography>
                <Typography variant="body2">
                  {metricsData[key] === null ? 'N/A' : formatMetricValue(metricsData[key], type)}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const renderFinancialMetrics = (data) => {
    if (!hasMetricsData('financial', data)) return null;

    return renderMetricsSection('financial', data);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleDetailViewChange = (event, newDetailView) => {
    if (newDetailView !== null) {
      setDetailView(newDetailView);
    }
  };

  const renderDetailContent = () => {
    if (!selectedAsset || !MetricsComponent) {
      return (
        <Typography color="text.secondary" align="center">
          Select an asset to view detailed metrics
        </Typography>
      );
    }

    return (
      <Box sx={{ mt: 2 }}>
        <Stack spacing={2}>
          {/* Detail View Toggle */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ToggleButtonGroup
              value={detailView}
              exclusive
              onChange={handleDetailViewChange}
              aria-label="detail view selector"
              size="small"
            >
              <ToggleButton value={DETAIL_VIEW_OPTIONS.TIMESERIES} aria-label="timeseries view">
                Timeseries
              </ToggleButton>
              <ToggleButton value={DETAIL_VIEW_OPTIONS.DAILY} aria-label="daily view">
                Daily Profile
              </ToggleButton>
              <ToggleButton value={DETAIL_VIEW_OPTIONS.MONTHLY} aria-label="monthly view">
                Monthly Distribution
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Render selected detail view */}
          <MetricsComponent
            simulationId={simulationId}
            scenarioId={String(scenarioId)}
            modelInstanceId={String(selectedAsset.id)}
            viewType={detailView}
          />
        </Stack>
      </Box>
    );
  };

  const renderContent = () => {
    switch (view) {
      case 'economics':
        return (
          <>
            {data && renderFinancialMetrics(data)}
            {data && hasMetricsData('financial', data) && (
              <>
                <Divider />
                <WidgetCashflow 
                  data={data} 
                  simulationId={simulationId}
                  scenarioId={scenarioId}
                />
              </>
            )}
          </>
        );
      case 'energy':
        return (
          <>
            {data && Object.keys(STATIC_METRICS_CONFIG).some(key => 
              key !== 'financial' && hasMetricsData(key, data)
            ) ? (
              <>
                <WidgetSankey data={data} />
              </>
            ) : (
              <Typography color="text.secondary" align="center">
                No energy metrics available for this scenario
              </Typography>
            )}
          </>
        );
      case 'detail':
        return renderDetailContent();
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography>Loading results...</Typography>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography color="error">Error: {error}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={3}>
          {/* Scenario Name and Topology Section */}
          <Box>
            <Typography variant="h5" gutterBottom>
              {scenarioName}
            </Typography>
            <Box sx={{ mt: 2, mb: 3 }}>
              <WidgetExistingScenarioTopology
                assets={assets}
                selectedAssetId={selectedAssetId}
                onAssetSelect={onAssetSelect}
              />
            </Box>
          </Box>

          {/* View Toggle */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="view selector"
              size="small"
            >
              <ToggleButton value="economics" aria-label="economics view">
                Economics
              </ToggleButton>
              <ToggleButton value="energy" aria-label="energy view">
                Energy
              </ToggleButton>
              <ToggleButton value="detail" aria-label="detail view">
                Detail
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Content based on selected view */}
          {renderContent()}
        </Stack>
      </CardContent>
    </Card>
  );
};

WidgetStaticResults.propTypes = {
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioName: PropTypes.string.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    icon: PropTypes.node,
    selectedInstance: PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  })).isRequired,
  selectedAssetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAssetSelect: PropTypes.func.isRequired,
  selectedAsset: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  MetricsComponent: PropTypes.elementType
};

export default WidgetStaticResults;
