// This component is used to display the mock of the collected data from the wizard.

import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import AppTheme from '../../shared-theme/AppTheme';
import ColorModeIconDropdown from '../../shared-theme/ColorModeIconDropdown';
import GridOnIcon from '@mui/icons-material/GridOn';
import NumbersIcon from '@mui/icons-material/Numbers';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Alert from '@mui/material/Alert';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const StyledLogo = styled('img')({
  width: '300px',
  height: 'auto',
  filter: 'none',
  opacity: '1'
});

const DataCard = ({ title, value, icon, description }) => (
  <Card 
    elevation={0}
    sx={{ 
      height: '100%',
      backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.8)
    }}
  >
    <CardContent>
      <Stack spacing={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          {icon}
          <Typography variant="h6">{title}</Typography>
        </Stack>
        <Typography variant="h4" color="primary">
          {value}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Stack>
    </CardContent>
  </Card>
);

DataCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired
};

export default function CollectedData() {
  const navigate = useNavigate();
  
  const logoPath = '/images/GeneralImages/vems_logo_dark.png';
  
  // Mock collected data
  const companyData = {
    name: "ACME Corporation",
    address: "123 Business Street, 1234 AB Amsterdam",
    logo: "/images/WizardImages/random-company-logo.png",
    buildingImage: "/images/WizardImages/random-company-pic.png",
    kvk: "69543219",
    btw: "NL002180439B01"
  };

  const collectedData = [
    {
      title: "Coordinates",
      value: "52.3676° N, 4.9041° E",
      icon: <LocationOnIcon color="primary" />,
      description: "Precise location coordinates"
    },
    {
      title: "EAN Number",
      value: "871685900012345",
      icon: <NumbersIcon color="primary" />,
      description: "European Article Number"
    },
    {
      title: "Grid Operator",
      value: "Liander",
      icon: <AccountBalanceIcon color="primary" />,
      description: "Local electricity gird operator"
    },
    {
      title: "Building Dimensions",
      value: "50 x 100 x 10 m",
      icon: <ViewInArIcon color="primary" />,
      description: "Total roof area 5000m²"
    },
    {
      title: "Existing Solar Panels",
      value: "1500 panels",
      icon: <GridOnIcon color="primary" />,
      description: "Detected via satellite imagery analysis"
    },
    {
      title: "Solar Irradiation",
      value: "1,050 kWh/m²",
      icon: <WbSunnyIcon color="primary" />,
      description: "Annual solar potential per square meter"
    }
  ];

  const handleCreateDigitalTwin = () => {
    navigate('/build-personal-digital-twin');
  };

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Box
        sx={(theme) => ({
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
          ...theme.applyStyles('dark', {
            backgroundImage:
              'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
          }),
        })}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            zIndex: 1000
          }}
        >
          <ColorModeIconDropdown />
        </Box>

        <Container>
          <Box
            sx={{
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '2rem',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

              }}
            >
              <StyledLogo
                src={logoPath}
                alt="VEMS Logo"
              />
              <Alert 
                icon={<WarningIcon />}
                severity="error"
                sx={{ 
                  mt: 3,
                  backgroundColor: (theme) => alpha(theme.palette.error.main, 0.1),
                  color: 'error.main',
                  '& .MuiAlert-icon': {
                    color: 'error.main'
                  },
                  fontWeight: 'bold'
                }}
              >
                Highly Congested Area
              </Alert>
            </Box>

            <Box sx={{ width: '100%', maxWidth: '1200px', mt: 4 }}>
              <Grid container spacing={3}>
                {/* First row - Company Profile Card */}
                <Grid item xs={12}>
                  <Card 
                    elevation={0}
                    sx={{ 
                      backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.8)
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={3} alignItems="center" sx={{ minHeight: 400 }}>
                        {/* Company Info */}
                        <Grid item xs={12} md={3}>
                          <Stack spacing={2} alignItems="center" justifyContent="center">
                            <Avatar
                              src={companyData.logo}
                              sx={{ 
                                width: 120,
                                height: 120,
                                mb: 2
                              }}
                            />
                            <Stack spacing={2} width="100%">
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <BusinessIcon fontSize="small" />
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                  {companyData.name}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="flex-start" spacing={1}>
                                <LocationOnIcon fontSize="small" sx={{ mt: 0.3 }} />
                                <Typography variant="body2" color="text.secondary">
                                  {companyData.address}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <BusinessCenterIcon fontSize="small" />
                                <Typography variant="body2" color="text.secondary">
                                  KVK: {companyData.kvk}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <ReceiptIcon fontSize="small" />
                                <Typography variant="body2" color="text.secondary">
                                  BTW: {companyData.btw}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Grid>

                        {/* Building Image */}
                        <Grid item xs={12} md={9}>
                          <Box
                            sx={{
                              width: '100%',
                              height: 400,
                              borderRadius: 1,
                              overflow: 'hidden',
                              backgroundColor: 'background.paper'
                            }}
                          >
                            <Box
                              component="img"
                              src={companyData.buildingImage}
                              alt="Company Building"
                              sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                backgroundColor: (theme) => 
                                  alpha(theme.palette.background.paper, 0.8)
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Second row - Data Cards */}
                {collectedData.map((data, index) => (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                    <DataCard {...data} />
                  </Grid>
                ))}

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <Button
                    variant="contained"
                    color="info"
                    size="small"
                    onClick={handleCreateDigitalTwin}
                    sx={{
                      minWidth: 150,
                      fontSize: '1rem',
                      color: '#fff'
                    }}
                  >
                    Build Your Digital Twin
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </AppTheme>
  );
}
