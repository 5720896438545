import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import MarketingPage from './app/marketing-page/MarketingPage';
// import UserHome from './app/user-interface/user-home/UserHome';
import UserDashboard from './app/user-interface/user-dashboard/UserDashboard';
import { simulatorRoutes } from './app/user-interface/user-simulator/SimulatorRoutes';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Intake from './app/wizard/intake/intake';
import LoadingDataCollection from './app/wizard/loading/loadingDataCollection';
import CollectedData from './app/wizard/collected-data/collectedData';
import BuildPersonalDigitalTwin from './app/wizard/build-personal-digital-twin/buildPersonalDigitalTwin';
import SignUp from './app/wizard/sign-up/SignUp';
import SignIn from './app/marketing-page/sign-in/SignIn';
import LoadingDigitalTwinCreation from './app/wizard/loading/loadinDigitalTwinCreation';
import CompareScenarios from './app/wizard/compare-scenarios/compareScenarios';

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<MarketingPage />} />
        <Route path="/login" element={<SignIn onClose={() => {}} />} />


        {/* STARTDEMO ROUTES */} 
        <Route path="/signup" element={<SignUp />} /> 
        
        {/* Protected wizard flow */}            
        <Route path="/intake" element={
          <ProtectedRoute>
            <Intake />
          </ProtectedRoute>
        } />
        <Route path="/loading" element={
          <ProtectedRoute>
            <LoadingDataCollection />
          </ProtectedRoute>
        } />
        <Route path="/collected-data" element={
          <ProtectedRoute>
            <CollectedData />
          </ProtectedRoute>
        } />
        <Route path="/build-personal-digital-twin" element={
          <ProtectedRoute>
            <BuildPersonalDigitalTwin />
          </ProtectedRoute>
        } />
        <Route path="/loading-digital-twin-creation" element={<LoadingDigitalTwinCreation />} />
        <Route path="/compare-scenarios" element={
          <ProtectedRoute>
            <CompareScenarios />
          </ProtectedRoute>
        } />
        {/* END DEMO ROUTES */}


        {/* Protected app routes */}
        {/* <Route path="/home" element={
          <ProtectedRoute>
            <UserHome />
          </ProtectedRoute>
        } /> */}
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <UserDashboard />
          </ProtectedRoute>
        } />
        {simulatorRoutes.map((route) => (
          <Route 
            key={route.path} 
            path={route.path} 
            element={
              <ProtectedRoute>
                {route.element}
              </ProtectedRoute>
            } 
          />
        ))}
      </Routes>
    </AuthProvider>
  );
} 