// This component is the dialog that the user can use to create a new asset or edit an existing asset.

import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  Stack,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import simulationService from '../../../../services/simulationService';
import assetModules from './asset-modules';
import ASSET_LIBRARY from '../existing-assets/AssetService';

export default function CreateAssetDialog({ open, onClose, category, onSubmit, editingAsset, onDelete, resetOnSuccess = true }) {
  const [assetData, setAssetData] = React.useState({
    name: '',
    specs: {}
  });
  const [profileFile, setProfileFile] = React.useState(null);
  const [statusMessage, setStatusMessage] = React.useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const AssetModule = assetModules[category?.id];
  const [validationErrors, setValidationErrors] = React.useState({});

  React.useEffect(() => {
    if (editingAsset) {
      setAssetData({
        name: editingAsset.name,
        specs: Object.entries(editingAsset.specs).reduce((acc, [key, value]) => {
          const param = AssetModule?.parameters?.find(p => p.name === key);
          acc[key] = value.replace(param?.unit || '', '');
          return acc;
        }, {})
      });
    } else {
      setAssetData({
        name: '',
        specs: {}
      });
      setProfileFile(null);
      setValidationErrors({});
      setStatusMessage('');
    }
  }, [editingAsset, category, open]);

  const handleParamChange = (paramName, value) => {
    if (paramName === 'profile' && value instanceof File) {
      setProfileFile(value);
    }
    
    setAssetData(prev => ({
      ...prev,
      specs: {
        ...prev.specs,
        [paramName]: value
      }
    }));
  };

  const handleSubmit = async () => {
    let createdModelId = null;
    let needsCleanup = true;

    try {
      if (!AssetModule) {
        throw new Error('Invalid asset category');
      }

      // Validate the data
      const errors = AssetModule.validate(assetData.specs);
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      // Transform data for API
      const modelData = AssetModule.transformForAPI(assetData.specs);
      modelData.name = assetData.name;

      // Create initial model instance
      const response = await simulationService.createModelInstance(modelData);
      
      if (!response.success) {
        throw new Error(response.error);
      }

      createdModelId = response.data.model.id;

      // Handle load shape profile creation
      if (modelData.type === 'load' && assetData.specs.creation_method === 'shape') {
        // Format the date correctly
        const startDate = new Date(assetData.specs.start_date);
        startDate.setMonth(0); // January
        startDate.setDate(1); // First day of month

        const loadProfileResponse = await simulationService.createLoadProfileForInstance(
          createdModelId,
          {
            daily_profile: assetData.specs.daily_profile || Array(12).fill(50),
            weekly_profile: assetData.specs.weekly_profile || Array(7).fill(50),
            yearly_profile: assetData.specs.yearly_profile || Array(12).fill(50),
            total_consumption: parseFloat(assetData.specs.total_consumption),
            start_date: startDate.toISOString().split('T')[0] // Format as YYYY-01-01
          }
        );
        
        if (!loadProfileResponse.success) {
          throw new Error(loadProfileResponse.error || 'Failed to create load profile');
        }
      }

      // Handle file upload for PV and load file methods
      if (profileFile instanceof File) {
        const uploadResponse = await simulationService.uploadModelInstanceProfile(
          createdModelId, 
          profileFile
        );
        
        if (!uploadResponse.success) {
          throw new Error(uploadResponse.error || 'File upload failed');
        }
      }

      // Force a refresh of the ASSET_LIBRARY
      if (category?.id) {
        await ASSET_LIBRARY.setSelectedCategory(category.id);
        await ASSET_LIBRARY.initialize();
      }

      needsCleanup = false;
      setStatusMessage('Asset created successfully!');
      
      if (resetOnSuccess) {
        setAssetData({
          name: '',
          specs: {}
        });
        setProfileFile(null);
        setValidationErrors({});
      }
      
      if (onSubmit) {
        onSubmit(response.data);
      }
      onClose();
    } catch (error) {
      console.error('Failed to create asset:', error);
      if (createdModelId && needsCleanup) {
        try {
          await simulationService.deleteModelInstance(createdModelId);
        } catch (cleanupError) {
          console.error('Failed to clean up model instance:', cleanupError);
        }
      }
      setStatusMessage(`Error: ${error.message}`);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await simulationService.deleteModelInstance(editingAsset.id);
      
      if (!response.success) {
        throw new Error(response.error);
      }

      setStatusMessage('Asset deleted successfully!');
      
      // Call onDelete callback to update parent component
      if (onDelete) {
        onDelete(editingAsset.id);
      }
      
      // Close both dialogs
      setDeleteDialogOpen(false);
      onClose();
      
    } catch (error) {
      console.error('Failed to delete asset:', error);
      setStatusMessage(`Error: ${error.message}`);
    }
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ 
          m: 0, 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography component="div" variant="h6">
            {editingAsset ? 'Edit' : 'Create New'} {category?.name}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ color: 'grey.500' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Stack spacing={3}>
            {statusMessage && (
              <Typography 
                color={statusMessage.includes('Error') ? 'error' : 'success'}
                sx={{ mt: 1 }}
              >
                {statusMessage}
              </Typography>
            )}
            
            <TextField
              label="Name"
              fullWidth
              value={assetData.name}
              onChange={(e) => setAssetData(prev => ({ ...prev, name: e.target.value }))}
            />

            {AssetModule && AssetModule.renderFields({
              data: assetData.specs,
              onChange: handleParamChange,
              errors: validationErrors
            })}
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          {editingAsset && (
            <Button 
              onClick={() => setDeleteDialogOpen(true)}
              variant="outlined"
              color="error"
              sx={{ mr: 'auto' }}
            >
              Delete
            </Button>
          )}
          <Button 
            onClick={onClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
          >
            {editingAsset ? 'Save Changes' : 'Create Asset'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete &ldquo;{editingAsset?.name}&rdquo;?
          </DialogContentText>
          {statusMessage && (
            <Typography 
              color={statusMessage.includes('Error') ? 'error' : 'success'}
              sx={{ mt: 2 }}
            >
              {statusMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

CreateAssetDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string
  }),
  editingAsset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    specs: PropTypes.object.isRequired
  }),
  resetOnSuccess: PropTypes.bool
}; 