// This widget is used to display the daily profile chart for the simulation.

import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ResponsiveBar } from '@nivo/bar';
import simulationService from '../../../../../../../services/simulationService';

const WidgetDailyProfile = ({ simulationId, scenarioId, modelInstanceId, title, metricKey }) => {
  const theme = useTheme();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await simulationService.getAssetDailyProfile(
          simulationId,
          scenarioId,
          modelInstanceId,
          metricKey
        );
        if (response.success) {
          setData(response.data);
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        console.error('Failed to fetch daily profile data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [simulationId, scenarioId, modelInstanceId, metricKey]);

  // Transform data for Nivo
  const chartData = React.useMemo(() => {
    if (!data?.values) return [];
    return data.values.map((value, index) => ({
      hour: `${String(index).padStart(2, '0')}:00`,
      value: value
    }));
  }, [data?.values]);

  // Memoize chart component
  const ChartComponent = React.useMemo(() => {
    if (!chartData.length) return null;
    
    return (
      <ResponsiveBar
        data={chartData}
        keys={['value']}
        indexBy="hour"
        margin={{ top: 10, right: 30, bottom: 50, left: 80 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        colors={theme.palette.primary.main}
        borderRadius={4}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: 'Hour',
          legendPosition: 'middle',
          legendOffset: 40,
          tickValues: chartData.map(d => d.hour)
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Value',
          legendPosition: 'middle',
          legendOffset: -60,
          format: value => value.toFixed(2)
        }}
        enableLabel={false}
        tooltip={({ value, indexValue }) => (
          <div
            style={{
              padding: 12,
              background: theme.palette.background.paper,
              color: theme.palette.text.primary,
              fontSize: '12px',
              borderRadius: '4px',
              boxShadow: theme.shadows[2]
            }}
          >
            <strong>{indexValue}</strong>: {value.toFixed(2)}
          </div>
        )}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: theme.palette.text.secondary
              }
            },
            legend: {
              text: {
                fill: theme.palette.text.primary
              }
            }
          },
          grid: {
            line: {
              stroke: theme.palette.divider,
              strokeWidth: 1,
              strokeDasharray: '4 4'
            }
          }
        }}
      />
    );
  }, [chartData, theme]);

  if (loading) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="text.secondary">Loading data...</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="error">Error: {error}</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (!data?.values?.length) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="text.secondary">No data available</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ height: 300, width: '100%' }}>
          {ChartComponent}
        </Box>
      </CardContent>
    </Card>
  );
};

WidgetDailyProfile.propTypes = {
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modelInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  metricKey: PropTypes.string.isRequired
};

export default WidgetDailyProfile; 