import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  Box,
  Typography,
  Stack
} from '@mui/material';
import { useDrag } from 'react-dnd';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const DraggableAsset = ({ asset }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'asset',
    item: asset,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Box
      ref={drag}
      sx={(theme) => ({
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        p: 3,
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        backgroundColor: theme.palette.background.paper,
        transition: 'all 0.3s ease',
        mb: 2,
        '&:hover': {
          bgcolor: theme.palette.action.hover,
          transform: 'translateY(-3px)',
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
        },
      })}
    >
      <Box sx={(theme) => ({ 
        color: theme.palette.primary.main,
        transform: 'scale(1.2)'
      })}>
        {asset.icon}
      </Box>
      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
        {asset.name}
      </Typography>
    </Box>
  );
};

DraggableAsset.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  }).isRequired,
};

const availableAssets = [
  {
    id: 'pv',
    name: 'PV System',
    type: 'pv',
    icon: <SolarPowerIcon color="primary" />,
  },
  {
    id: 'storage',
    name: 'Energy Storage',
    type: 'battery',
    icon: <BatteryChargingFullIcon color="primary" />,
  },
  {
    id: 'load',
    name: 'Load',
    type: 'load',
    icon: <HomeIcon color="primary" />,
  },
  {
    id: 'controller',
    name: 'Controller',
    type: 'controller',
    icon: <SettingsIcon color="primary" />,
  },
  {
    id: 'tariff',
    name: 'Tariff',
    type: 'tariff',
    icon: <AttachMoneyIcon color="primary" />,
  },
];

export default function AssetsList() {
  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={1}>
        {availableAssets.map((asset) => (
          <DraggableAsset key={asset.id} asset={asset} />
        ))}
      </Stack>
    </Box>
  );
} 