import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Typography, 
  Stack, 
  Card, 
  CardContent,
  IconButton,
  Collapse,
  Grid,
  CircularProgress
} from '@mui/material';
import { useDrop } from 'react-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { alpha } from '@mui/material/styles';
import AssetCard from '../../../user-interface/common-components/existing-assets/AssetCard';
import CreateAssetDialog from '../../../user-interface/common-components/create-asset/CreateNewAssetDialog';

export default function DropZone({ 
  onDrop, 
  placedAssets, 
  onAssetRemove, 
  onAssetInstanceSelect,
  modelInstances,
  loadingInstances,
  expandedAssets,
  onAssetExpand,
  onNewAssetCreated
}) {
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [creatingAssetType, setCreatingAssetType] = React.useState(null);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'asset',
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const handleNewAssetCreated = (newAsset) => {
    onNewAssetCreated(newAsset, creatingAssetType);
    setCreateDialogOpen(false);
    setCreatingAssetType(null);
  };

  return (
    <Box
      ref={drop}
      sx={{
        height: '100%',
        minHeight: 500,
        border: '3px dashed',
        borderColor: isOver ? 'primary.main' : 'divider',
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        mt: 3,
        transition: 'border-color 0.2s ease',
      }}
    >
      <Stack spacing={2} sx={{ 
        height: '100%',
        minHeight: 'inherit',
        ...(placedAssets.length === 0 && {
          justifyContent: 'center'
        })
      }}>
        {placedAssets.length === 0 && (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1,
            }}
          >
            <Typography 
              color="text.secondary"
              variant="h6"
              sx={{
                fontSize: '1.25rem',
                fontWeight: 400,
                textAlign: 'center'
              }}
            >
              Drag and drop assets here to build your digital twin
            </Typography>
          </Box>
        )}

        {placedAssets.map((asset) => (
          <Card
            key={asset.id}
            elevation={0}
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.8),
              borderRadius: 2,
            }}
          >
            <CardContent sx={{ pb: 1 }}>
              <Stack spacing={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="column" spacing={0.5}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      {asset.icon}
                      <Typography variant="subtitle1">{asset.name}</Typography>
                    </Stack>
                    {asset.selectedInstance && !expandedAssets[asset.id] && (
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ ml: 4 }}
                      >
                        Selected Model: {asset.selectedInstance.name}
                      </Typography>
                    )}
                  </Stack>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => onAssetExpand(asset.id)}
                      sx={{
                        transform: expandedAssets[asset.id] ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s',
                      }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => onAssetRemove(asset.id)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Stack>

                <Collapse in={expandedAssets[asset.id]}>
                  <Stack spacing={2} sx={{ mt: 2, px: 2 }}>
                    {loadingInstances[asset.type] ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Card
                            variant="outlined"
                            sx={{
                              cursor: 'pointer',
                              height: '100%',
                              bgcolor: 'background.paper',
                              borderStyle: 'dashed',
                              borderWidth: 2,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              '&:hover': {
                                borderColor: 'primary.main',
                                bgcolor: 'action.hover',
                              },
                              transition: 'all 0.2s'
                            }}
                            onClick={() => {
                              setCreatingAssetType(asset.type);
                              setCreateDialogOpen(true);
                            }}
                          >
                            <CardContent>
                              <Stack
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ py: 1 }}
                              >
                                <AddIcon color="primary" sx={{ fontSize: 30 }} />
                                <Typography variant="subtitle1" color="primary">
                                  Create New {asset.name}
                                </Typography>
                              </Stack>
                            </CardContent>
                          </Card>
                        </Grid>

                        {modelInstances[asset.type]?.map((instance) => (
                          <Grid item xs={12} sm={6} md={4} key={instance.id}>
                            <AssetCard
                              asset={{
                                id: instance.id,
                                name: instance.name,
                                type: instance.type || asset.type,
                                specs: instance.specs || {},
                                params: instance.params || {}
                              }}
                              selected={asset.selectedInstance?.id === instance.id}
                              showSelectionIndicator={true}
                              onClick={() => onAssetInstanceSelect(asset.id, instance)}
                              actions={false}
                              sx={{
                                height: '100%'
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Stack>
                </Collapse>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <CreateAssetDialog
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
          setCreatingAssetType(null);
        }}
        onSubmit={handleNewAssetCreated}
        category={creatingAssetType ? {
          id: creatingAssetType.toLowerCase(),
          name: placedAssets.find(a => a.type === creatingAssetType)?.name || '',
          description: ''
        } : null}
      />
    </Box>
  );
}

DropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  placedAssets: PropTypes.array.isRequired,
  onAssetRemove: PropTypes.func.isRequired,
  onAssetInstanceSelect: PropTypes.func.isRequired,
  modelInstances: PropTypes.object.isRequired,
  loadingInstances: PropTypes.object.isRequired,
  expandedAssets: PropTypes.object.isRequired,
  onAssetExpand: PropTypes.func.isRequired,
  onNewAssetCreated: PropTypes.func.isRequired,
}; 