import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  Stack, 
  Button,
  Alert,
  Typography
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import api from '../../../../../services/api';

export default function UploadProfileMethod({ onChange, templateName = 'profile_template' }) {
  const [error, setError] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);

  // Map template names to their server endpoints
  const getTemplateEndpoint = (name) => {
    const templateMap = {
      'pv_profile_template': 'pv_profile',
      'load_profile_template': 'load'
    };
    return templateMap[name] || name;
  };

  const handleTemplateDownload = async () => {
    try {
      setError(null);
      const endpoint = getTemplateEndpoint(templateName);
      
      // Use api service to make the request
      const response = await api.get(`/templates/${endpoint}`, {
        responseType: 'blob'
      });
      
      // Create a URL for the blob
      const blob = new Blob([response.data]);
      const downloadUrl = window.URL.createObjectURL(blob);
      
      // Create and trigger download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `${templateName}.xlsx`;
      document.body.appendChild(link);
      link.click();
      
      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading template:', error);
      setError('Failed to download template. Please try again later.');
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);
      
      // Create a new File object to ensure it's properly structured
      const secureFile = new File([file], file.name, {
        type: file.type,
        lastModified: file.lastModified,
      });
      
      // Validate file type
      const validTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
        'application/vnd.ms-excel', // xls
        'text/csv' // csv
      ];
      
      if (!validTypes.includes(secureFile.type)) {
        setError('Please upload a valid Excel or CSV file');
        setSelectedFile(null);
        onChange('profile', null);
        event.target.value = ''; // Reset file input
        return;
      }

      setError(null);
      setSelectedFile(secureFile);
      onChange('profile', secureFile);
    }
  };

  return (
    <Stack spacing={2}>
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <Button
        variant="outlined"
        startIcon={<DownloadIcon />}
        onClick={handleTemplateDownload}
        fullWidth
      >
        Download Template
      </Button>
      <Button
        variant="outlined"
        component="label"
        startIcon={<UploadFileIcon />}
        fullWidth
        color={selectedFile ? "success" : "primary"}
      >
        {selectedFile ? 'File Selected' : 'Upload Profile'}
        <input
          type="file"
          hidden
          accept=".csv,.xlsx,.xls"
          onChange={handleFileSelect}
        />
      </Button>
      {selectedFile && (
        <Typography variant="caption" color="text.secondary" align="center">
          Selected file: {selectedFile.name}
        </Typography>
      )}
    </Stack>
  );
}

UploadProfileMethod.propTypes = {
  onChange: PropTypes.func.isRequired,
  templateName: PropTypes.string
}; 