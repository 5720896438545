import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

export default function WidgetMessagesDisplayer({ open, message, severity, onClose }) {
  const theme = useTheme();

  const alertStyles = {
    success: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    info: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert 
        onClose={onClose} 
        severity={severity} 
        variant="filled" 
        sx={{ 
          width: '100%',
          ...alertStyles[severity],
          '& .MuiAlert-icon': {
            color: alertStyles[severity].color,
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

WidgetMessagesDisplayer.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['success', 'error', 'info', 'warning']).isRequired,
  onClose: PropTypes.func.isRequired,
};
