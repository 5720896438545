// This component is used to allow the user to search for their company using Google Maps.

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { styled, useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import googleMapsLoader from '../../../../utils/googleMapsLoader';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '700px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

// Custom styles for the Google Places Autocomplete dropdown
const autocompleteStyles = (theme) => `
  .pac-container {
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.divider};
    box-shadow: ${theme.shadows[3]};
    background-color: ${theme.palette.background.paper};
    font-family: ${theme.typography.fontFamily};
    padding: ${theme.spacing(1)} 0;
    margin-top: ${theme.spacing(2)};
    width: 700px !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .pac-item {
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    color: ${theme.palette.text.primary};
    font-size: ${theme.typography.body2.fontSize};
    cursor: pointer;
    border-top: none;
    
    &:hover {
      background-color: ${theme.palette.action.hover};
    }
  }

  .pac-item-selected {
    background-color: ${theme.palette.action.selected};
  }

  .pac-icon {
    display: none;
  }

  .pac-item-query {
    font-size: ${theme.typography.body1.fontSize};
    padding-right: ${theme.spacing(1)};
    color: ${theme.palette.text.primary};
  }

  .pac-matched {
    color: ${theme.palette.primary.main};
    font-weight: ${theme.typography.fontWeightMedium};
  }

  .pac-item span:not(.pac-item-query) {
    color: ${theme.palette.text.secondary};
    font-size: ${theme.typography.caption.fontSize};
  }
`;

export default function WidgetCompanyBar() {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [loadError, setLoadError] = React.useState(null);
  const inputRef = React.useRef(null);

  // Inject custom styles when component mounts
  React.useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.textContent = autocompleteStyles(theme);
    document.head.appendChild(styleTag);

    return () => {
      document.head.removeChild(styleTag);
    };
  }, [theme]);

  React.useEffect(() => {
    setLoading(true);
    googleMapsLoader
      .load()
      .then(() => {
        setIsLoaded(true);
        if (inputRef.current) {
          initAutocomplete();
        }
      })
      .catch((error) => {
        setLoadError(error);
        console.error('Google Maps loading error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const initAutocomplete = () => {
    if (!window.google || !inputRef.current) return;

    const autocompleteInstance = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ['establishment'],
        fields: ['name', 'formatted_address', 'place_id', 'geometry'],
      }
    );

    autocompleteInstance.addListener('place_changed', () => {
      setLoading(true);
      const place = autocompleteInstance.getPlace();
      handlePlaceSelect(place);
      setLoading(false);
    });
  };

  const handlePlaceSelect = (place) => {
    if (!place.geometry) {
      console.log("No location data available for this place");
      return;
    }

    // Here you can handle the selected place data
    console.log('Selected place:', {
      name: place.name,
      address: place.formatted_address,
      location: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
    });
  };

  if (loadError) {
    console.error('Error loading Google Maps:', loadError);
  }

  return (
    <Box sx={{ 
      width: '100%', 
      mt: 6, 
      display: 'flex',
      justifyContent: 'center'
    }}>
      <StyledPaper>
        <TextField
          inputRef={inputRef}
          fullWidth
          placeholder="Search for your company"
          variant="outlined"
          disabled={!isLoaded}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
            endAdornment: (!isLoaded || loading) ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : null,
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              padding: '12px 14px',
            }
          }}
        />
      </StyledPaper>
    </Box>
  );
}
