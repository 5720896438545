// Auth context for the user interface
// Provides authentication state and login/logout functionality, Stores user details in local storage

import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { authService } from '../services/api';
import PropTypes from 'prop-types';
import { TOKEN_REFRESH_EVENT } from '../services/api';
import { shouldRefreshToken, getTokenExpiryTime } from '../utils/tokenUtils';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const refreshTimerRef = useRef(null);

  const checkAuth = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('authToken');
      if (token) {
        const response = await authService.whoami();
        if (response.user_details) {
          setUser(response.user_details);
        }
      }
    } catch (error) {
      console.error('Auth check failed:', error);
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      checkAuth();
    }
  }, []);

  useEffect(() => {
    const handleTokenRefresh = (event) => {
      // Update any necessary state or perform actions when token is refreshed
      console.log('Token refreshed:', event.detail.token);
    };

    window.addEventListener(TOKEN_REFRESH_EVENT, handleTokenRefresh);

    return () => {
      window.removeEventListener(TOKEN_REFRESH_EVENT, handleTokenRefresh);
    };
  }, []);


  // FUNCTION REALIZED FOR THE DEMO
  const signup = async (credentials) => {
    try {
      // Here you would typically make an API call to your backend
      // For now, we'll simulate a successful signup
      console.log('Signup credentials:', credentials);
      
      return {
        success: true,
        message: 'Signup successful'
      };
    } catch (error) {
      console.error('Signup error:', error);
      return {
        success: false,
        message: error.message || 'Failed to sign up'
      };
    }
  };
  // END DEMO FUNCTIONALITY


  const login = async (credentials) => {
    setLoading(true);
    try {
      const response = await authService.login(credentials);
      if (response.success) {
        await checkAuth();
        scheduleTokenRefresh();
      }
      return response;
    } finally {
      setLoading(false);
    }
  };

  const fetchActiveSessions = async () => {
    try {
      const response = await authService.getSessions();
      if (response.success) {
        setSessions(response.data.sessions);
      }
    } catch (error) {
      console.error('Failed to fetch sessions:', error);
    }
  };

  const terminateSession = async (sessionId) => {
    try {
      const response = await authService.terminateSession(sessionId);
      if (response.success) {
        setSessions(prev => prev.filter(session => session.id !== sessionId));
      }
      return response;
    } catch (error) {
      console.error('Failed to terminate session:', error);
      return { success: false, error: error.message };
    }
  };

  const logout = async (allSessions = false) => {
    setLoading(true);
    try {
      if (allSessions) {
        await authService.logoutAll();
      } else {
        await authService.logout();
      }
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current);
      }
      setUser(null);
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      setLoading(false);
    }
  };

  const refreshToken = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        throw new Error('No token available');
      }

      if (shouldRefreshToken(token)) {
        const response = await authService.refreshToken();
        
        if (response?.access_token) {
          localStorage.setItem('authToken', response.access_token);
          window.dispatchEvent(new CustomEvent(TOKEN_REFRESH_EVENT, {
            detail: { token: response.access_token }
          }));
          return true;
        }
      }
      return false;
    } catch (error) {
      console.error('Background token refresh failed:', error);
      if (error.response?.status === 401) {
        logout();
      }
      return false;
    }
  };

  const scheduleTokenRefresh = () => {
    if (refreshTimerRef.current) {
      clearTimeout(refreshTimerRef.current);
    }

    const token = localStorage.getItem('authToken');
    if (!token) return;

    const expiryTime = getTokenExpiryTime(token);
    if (!expiryTime) return;

    const refreshThreshold = 14 * 60 * 1000; // 14 minutes
    const timeUntilRefresh = expiryTime - Date.now() - refreshThreshold;

    if (timeUntilRefresh > 0) {
      refreshTimerRef.current = setTimeout(() => {
        refreshToken().then((refreshed) => {
          if (refreshed) {
            // Schedule next refresh only if current refresh was successful
            scheduleTokenRefresh();
          }
        });
      }, timeUntilRefresh);
    } else {
      // If we're already past the refresh threshold, refresh immediately
      refreshToken().then((refreshed) => {
        if (refreshed) {
          scheduleTokenRefresh();
        }
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      scheduleTokenRefresh();
    }

    return () => {
      if (refreshTimerRef.current) {
        clearTimeout(refreshTimerRef.current);
      }
    };
  }, []);

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        loading, 
        signup, 
        login, 
        logout,
        sessions,
        fetchActiveSessions,
        terminateSession 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 