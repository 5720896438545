// Protected route for the user interface. 
// Redirects to the login page if the user is not authenticated
// Saves the attempted URL in the location state to redirect to after login

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import SignIn from '../app/marketing-page/sign-in/SignIn';
import { AUTH_REQUIRED_EVENT } from '../services/api';
import { useIdleTimeout } from '../hooks/useIdleTimeout';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export default function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();
  const location = useLocation();
  const { showWarning } = useIdleTimeout();

  useEffect(() => {
    // Listen for auth required events
    const handleAuthRequired = () => {
      // Force a re-render which will show the login dialog
      // since user will be null
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
    };

    window.addEventListener(AUTH_REQUIRED_EVENT, handleAuthRequired);

    return () => {
      window.removeEventListener(AUTH_REQUIRED_EVENT, handleAuthRequired);
    };
  }, []);

  // Show loading state while checking auth
  if (loading) {
    return <div>Loading...</div>;
  }

  // If not authenticated, only show the login dialog
  if (!user) {
    return (
      <>
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          backdropFilter: 'blur(10px)',
          zIndex: 999,
        }} />
        <SignIn 
          onClose={() => {}} // No-op since we'll redirect on successful login
          redirectPath={location.pathname}
        />
      </>
    );
  }

  // Only render children if authenticated
  return (
    <>
      {/* Idle warning dialog */}
      <Dialog open={showWarning}>
        <DialogTitle>Session Timeout Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your session will expire in 1 minute due to inactivity.
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {children}
    </>
  );
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}; 