// This widget displays the cashflow analysis for the simulation over a specified time period

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chart from 'react-apexcharts';
import simulationService from '../../../../../../../services/simulationService';
import { useTheme } from '@mui/material/styles';

const WidgetCashflow = ({ data, simulationId, scenarioId, years = 20 }) => {
  const theme = useTheme();
  const [modelInstances, setModelInstances] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  // Fetch model instances data
  React.useEffect(() => {
    const fetchModelInstances = async () => {
      setLoading(true);
      try {
        const response = await simulationService.getSimulationScenarios(simulationId);
        if (response.success) {
          // Find the correct scenario and get its model_instances
          const scenario = response.data.scenarios.find(s => s.id === parseInt(scenarioId));
          if (scenario) {
            setModelInstances(scenario.model_instances);
          }
        }
      } catch (error) {
        console.error('Failed to fetch model instances:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchModelInstances();
  }, [simulationId, scenarioId]);

  // Calculate costs per component type
  const calculateComponentCosts = () => {
    if (!modelInstances) {
      return {
        pv: { capex: 0, opex: 0 },
        battery: { capex: 0, opex: 0 }
      };
    }

    const costs = {
      pv: { capex: 0, opex: 0 },
      battery: { capex: 0, opex: 0 }
    };

    modelInstances.forEach(asset => {
      // Only process PV and Battery assets
      if (asset.type.includes('pv')) {
        if (asset.capital_cost) {
          costs.pv.capex += Number(asset.capital_cost) || 0;
        }
        if (asset.om_cost) {
          costs.pv.opex += Number(asset.om_cost) || 0;
        }
      } else if (asset.type.includes('battery')) {
        if (asset.capital_cost) {
          costs.battery.capex += Number(asset.capital_cost) || 0;
        }
        if (asset.om_cost) {
          costs.battery.opex += Number(asset.om_cost) || 0;
        }
      }
    });

    return costs;
  };

  const prepareCashflowData = () => {
    const yearLabels = Array.from({ length: years }, (_, i) => `Year ${i + 1}`);
    const componentCosts = calculateComponentCosts();
    
    // Get energy costs from financial metrics
    const {
      total_export_revenue = 0,
      total_import_cost = 0
    } = data?.financial_metrics || {};

    // Calculate yearly values - NO DIVISION BY YEARS since they're already annual values
    const yearlyExportRevenue = Math.abs(Number(total_export_revenue) || 0);
    const yearlyImportCost = (Number(total_import_cost) || 0);

    // Ensure componentCosts exists and has all required properties
    const {
      pv = { capex: 0, opex: 0 },
      battery = { capex: 0, opex: 0 }
    } = componentCosts;

    // Initialize series array
    const series = [];

    // Only add PV data if there are PV costs
    if (pv.capex > 0 || pv.opex > 0) {
      const pvCapexData = new Array(years).fill(0);
      pvCapexData[0] = pv.capex;
      series.push({
        name: 'PV CAPEX',
        data: pvCapexData.map(value => -value)
      });

      if (pv.opex > 0) {
        const pvOpexData = new Array(years).fill(pv.opex);
        series.push({
          name: 'PV OPEX',
          data: pvOpexData.map(value => -value)
        });
      }
    }

    // Only add Battery data if there are Battery costs
    if (battery.capex > 0 || battery.opex > 0) {
      const batteryCapexData = new Array(years).fill(0);
      batteryCapexData[0] = battery.capex;
      series.push({
        name: 'Battery CAPEX',
        data: batteryCapexData.map(value => -value)
      });

      if (battery.opex > 0) {
        const batteryOpexData = new Array(years).fill(battery.opex);
        series.push({
          name: 'Battery OPEX',
          data: batteryOpexData.map(value => -value)
        });
      }
    }

    // Only add energy trading costs if they exist and are non-zero
    if (yearlyImportCost > 0.01) {
      series.push({
        name: 'Import Cost',
        data: new Array(years).fill(yearlyImportCost).map(value => -value)
      });
    }

    if (yearlyExportRevenue > 0.01) {
      series.push({
        name: 'Export Revenue',
        data: new Array(years).fill(yearlyExportRevenue)
      });
    }

    // If no series were added (no costs at all), add a dummy series to show zero
    if (series.length === 0) {
      series.push({
        name: 'No Costs',
        data: new Array(years).fill(0)
      });
    }

    return {
      categories: yearLabels,
      series
    };
  };

  // Memoize the chart data
  const chartData = React.useMemo(() => {
    const data = prepareCashflowData();
    
    // Calculate cumulative cashflow
    const cumulativeCashflow = data.series.reduce((acc, series) => {
      series.data.forEach((value, index) => {
        acc[index] = (acc[index] || 0) + value;
      });
      return acc;
    }, []);

    // Add cumulative line series
    data.series.push({
      name: 'Net Cashflow',
      type: 'line',
      data: cumulativeCashflow
    });

    return data;
  }, [modelInstances, data?.financial_metrics, years]);

  // Update the colors array to be dynamic based on available series
  const getChartColors = (series) => {
    const colorMap = {
      'PV CAPEX': '#ff7f0e',      // Orange
      'PV OPEX': '#ffbb78',       // Light orange
      'Battery CAPEX': '#1f77b4',  // Blue
      'Battery OPEX': '#aec7e8',   // Light blue
      'Import Cost': '#4287f5',    // Changed from '#d62728' (red) to '#4287f5' (blue)
      'Export Revenue': '#2ecc71', // Green
      'Net Cashflow': '#ff0000',   // Bright red
      'No Costs': '#cccccc'        // Gray
    };

    return series.map(s => colorMap[s.name]);
  };

  // Memoize the chart options
  const chartOptions = React.useMemo(() => {
    // Get the appropriate text color based on theme mode
    const textColor = theme.palette.mode === 'dark' ? '#fff' : '#000';

    return {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          borderRadius: 4,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: Array(chartData.series.length - 1).fill(0).concat(3),
        curve: 'smooth',
        colors: Array(chartData.series.length - 1).fill('transparent').concat('#ff0000')
      },
      xaxis: {
        categories: chartData.categories,
        labels: {
          rotate: -45,
          style: {
            fontSize: '12px',
            colors: textColor
          }
        }
      },
      yaxis: {
        title: {
          text: 'Cost (k€)',
          style: {
            color: textColor
          }
        },
        labels: {
          formatter: (value) => {
            // Convert to thousands without decimal places
            return `${Math.round(Math.abs(value) / 1000)}`;
          },
          style: {
            colors: textColor
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        enabled: true,
        shared: true,
        followCursor: true,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        fillSeriesColor: false,
        theme: theme.palette.mode,
        style: {
          fontSize: '12px'
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: true,
        },
        y: {
          formatter: (value) => {
            // Convert to thousands without decimal places
            return `${Math.round(Math.abs(value) / 1000)}k€`;
          }
        },
        marker: {
          show: false
        },
        items: {
          display: 'flex',
        },
        fixed: {
          enabled: false,
          position: 'topLeft',
          offsetX: 0,
          offsetY: 0,
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        onItemHover: {
          highlightDataSeries: false
        },
        labels: {
          colors: textColor
        }
      },
      colors: [...getChartColors(chartData.series)],
    };
  }, [chartData, theme.palette.mode]);

  if (loading || !data?.financial_metrics || !modelInstances) {
    return (
      <Box sx={{ 
        height: 400, 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Typography color="text.primary">
          Loading financial data...
        </Typography>
      </Box>
    );
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom color="text.primary">
          Cashflow
        </Typography>
        <Box sx={{ height: 400 }}>
          <Chart
            options={chartOptions}
            series={chartData.series}
            type="bar"
            height={400}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

WidgetCashflow.propTypes = {
  data: PropTypes.object.isRequired,
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  years: PropTypes.number
};

export default WidgetCashflow;