// This component is the simulation header for the simulation, it is intended to be used within the ExistingSimulationGrid component.
// Here you can find the simulation name and status.

import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

export default function WidgetSimulationHeader({ simulationName, status }) {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'running':
        return 'primary';
      case 'completed':
        return 'success';
      case 'error':
        return 'error';
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography variant="h4">
        {simulationName}
      </Typography>
      <Chip 
        label={status.toUpperCase()}
        color={getStatusColor(status)}
        size="medium"
      />
    </Stack>
  );
}

WidgetSimulationHeader.propTypes = {
  simulationName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}; 