import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Stack, 
  Slider, 
  Typography, 
  TextField,
  InputAdornment,
  Paper,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const HOURS = Array.from({ length: 12 }, (_, i) => {
  const startHour = i * 2;
  const endHour = (i * 2 + 2) % 24;
  return {
    label: `${String(startHour).padStart(2, '0')}\n${String(endHour).padStart(2, '0')}`,
    key: `h${startHour}`,
    tooltip: `${String(startHour).padStart(2, '0')}h - ${String(endHour).padStart(2, '0')}h`
  };
});

const DAYS = [
  { label: 'Monday', key: 'mon' },
  { label: 'Tuesday', key: 'tue' },
  { label: 'Wednesday', key: 'wed' },
  { label: 'Thursday', key: 'thu' },
  { label: 'Friday', key: 'fri' },
  { label: 'Saturday', key: 'sat' },
  { label: 'Sunday', key: 'sun' }
];

const MONTHS = [
  { label: 'Jan', key: 'jan' },
  { label: 'Feb', key: 'feb' },
  { label: 'Mar', key: 'mar' },
  { label: 'Apr', key: 'apr' },
  { label: 'May', key: 'may' },
  { label: 'Jun', key: 'jun' },
  { label: 'Jul', key: 'jul' },
  { label: 'Aug', key: 'aug' },
  { label: 'Sep', key: 'sep' },
  { label: 'Oct', key: 'oct' },
  { label: 'Nov', key: 'nov' },
  { label: 'Dec', key: 'dec' }
];

const PERIODS = {
  daily: { title: 'Daily Distribution', items: HOURS },
  weekly: { title: 'Weekly Distribution', items: DAYS },
  yearly: { title: 'Yearly Distribution', items: MONTHS }
};

export default function ShapeSliderMethod({ data, onChange, errors = {} }) {
  const [selectedPeriod, setSelectedPeriod] = React.useState('daily');

  const handlePeriodChange = (event, newPeriod) => {
    if (newPeriod !== null) {
      setSelectedPeriod(newPeriod);
    }
  };

  const preventHorizontalKeyboardNavigation = (event) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  };

  React.useEffect(() => {
    if (!data.total_consumption) {
      onChange('total_consumption', '');
    }
    
    // Initialize all periods with default values
    PERIODS.daily.items.forEach(item => {
      if (!data[item.key]) onChange(item.key, 50);
    });
    
    PERIODS.weekly.items.forEach(item => {
      if (!data[item.key]) onChange(item.key, 50);
    });
    
    PERIODS.yearly.items.forEach(item => {
      if (!data[item.key]) onChange(item.key, 50);
    });
  }, []);

  // Initialize arrays with default values if not present
  React.useEffect(() => {
    if (!data.daily_profile) {
      onChange('daily_profile', Array(12).fill(50));
    }
    if (!data.weekly_profile) {
      onChange('weekly_profile', Array(7).fill(50));
    }
    if (!data.yearly_profile) {
      onChange('yearly_profile', Array(12).fill(50));
    }
  }, []);

  const handleSliderChange = (index, value, periodType) => {
    const currentProfile = [...(data[`${periodType}_profile`] || [])];
    currentProfile[index] = value;
    onChange(`${periodType}_profile`, currentProfile);
  };

  const getSliderValue = (index, periodType) => {
    const profile = data[`${periodType}_profile`];
    return profile ? profile[index] : 50;
  };

  return (
    <Stack spacing={3}>
      <TextField
        label="Total Annual Consumption"
        type="number"
        value={data.total_consumption || ''}
        onChange={(e) => onChange('total_consumption', e.target.value)}
        error={!!errors?.total_consumption}
        helperText={errors?.total_consumption}
        InputProps={{
          endAdornment: <InputAdornment position="end">kWh</InputAdornment>
        }}
      />

      <Box>
        <ToggleButtonGroup
          value={selectedPeriod}
          exclusive
          onChange={handlePeriodChange}
          fullWidth
          sx={{ mb: 2 }}
        >
          <ToggleButton value="daily">
            <Stack direction="row" spacing={1} alignItems="center">
              <AccessTimeIcon />
              <Typography>Daily Profile</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value="weekly">
            <Stack direction="row" spacing={1} alignItems="center">
              <ViewWeekIcon />
              <Typography>Weekly Profile</Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton value="yearly">
            <Stack direction="row" spacing={1} alignItems="center">
              <CalendarMonthIcon />
              <Typography>Yearly Profile</Typography>
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>

        <Paper elevation={2} sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            {PERIODS[selectedPeriod].title}
          </Typography>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              height: 300,
              mt: 3
            }}
          >
            {PERIODS[selectedPeriod].items.map((item, index) => (
              <Box 
                key={item.key} 
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: 1
                }}
              >
                <Box sx={{ height: 250 }}>
                  <Slider
                    orientation="vertical"
                    value={getSliderValue(index, selectedPeriod)}
                    onChange={(_, value) => handleSliderChange(index, value, selectedPeriod)}
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    title={item.tooltip}
                    sx={{
                      height: '100%',
                      '& .MuiSlider-root': {
                        writingMode: 'vertical-lr',
                        direction: 'rtl'
                      }
                    }}
                    onKeyDown={preventHorizontalKeyboardNavigation}
                  />
                </Box>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    mt: 1,
                    fontSize: '0.75rem',
                    textAlign: 'center',
                    maxWidth: '100%',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                    lineHeight: 1.2
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </Box>
    </Stack>
  );
}

ShapeSliderMethod.propTypes = {
  data: PropTypes.shape({
    total_consumption: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    daily_profile: PropTypes.arrayOf(PropTypes.number),
    weekly_profile: PropTypes.arrayOf(PropTypes.number),
    yearly_profile: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};
