// Options menu for the user profile (contains profile, logout, and settings)

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Divider, { dividerClasses } from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { listClasses } from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const MenuItem = styled(MuiMenuItem)({
  margin: '2px 0',
});

export default function OptionsMenu({ anchorEl, onClose, open: menuOpen, hideButton }) {
  const [localAnchorEl, setLocalAnchorEl] = React.useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setLocalAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLocalAnchorEl(null);
    if (onClose) onClose();
  };

  // Use provided props if they exist, otherwise use local state
  const finalAnchorEl = anchorEl || localAnchorEl;
  const finalOpen = menuOpen !== undefined ? menuOpen : Boolean(localAnchorEl);

  const handleLogout = async () => {
    try {
      await logout();
      handleClose();
      navigate('/'); // Redirect to marketing page
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <>
      {!anchorEl && !hideButton && ( // Only show IconButton if not using external anchor and not hidden
        <IconButton 
          onClick={handleClick}
          size="small"
          sx={{ borderColor: 'transparent' }}
        >
          <MoreVertRoundedIcon />
        </IconButton>
      )}
      <Menu
        anchorEl={finalAnchorEl}
        id="menu"
        open={finalOpen}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          [`& .${listClasses.root}`]: {
            padding: '4px',
          },
          [`& .${paperClasses.root}`]: {
            padding: 0,
          },
          [`& .${dividerClasses.root}`]: {
            margin: '4px -4px',
          },
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>Add another account</MenuItem>
        <MenuItem onClick={handleClose}>Settings</MenuItem>
        <Divider />
        <MenuItem
          onClick={handleLogout}
          sx={{
            [`& .${listItemIconClasses.root}`]: {
              ml: 'auto',
              minWidth: 0,
            },
          }}
        >
          <ListItemText>Logout</ListItemText>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </>
  );
}

OptionsMenu.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  hideButton: PropTypes.bool,
};
