// This page contains the page in which the user will enter their company information.

import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import AppTheme from '../../shared-theme/AppTheme';
import ColorModeIconDropdown from '../../shared-theme/ColorModeIconDropdown';
import WidgetCompanyBar from './components/WidgetCompanyBar';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

// Reuse the styling pattern from the marketing page for consistency
const StyledLogo = styled('img')(({ theme }) => ({
  width: '300px',
  height: 'auto',
  marginBottom: theme.spacing(4),
  filter: 'none',
  opacity: '1'
}));

export default function Intake() {
  const navigate = useNavigate();
  // Use the same theme-aware logo selection as other components
  const logoPath = '/images/GeneralImages/vems_logo_dark.png';

  const handleStartClick = () => {
    navigate('/loading');  // Adjust this path according to your router setup
  };

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Box
        sx={(theme) => ({
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
          ...theme.applyStyles('dark', {
            backgroundImage:
              'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
          }),
        })}
      >
        {/* Theme Toggle Button */}
        <Box
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            zIndex: 1000
          }}
        >
          <ColorModeIconDropdown />
        </Box>

        <Container maxWidth="sm">
          <Box
            sx={{
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '2.5rem',
              paddingBottom: '2rem',
              transform: 'translateY(-5%)',
            }}
          >
            <StyledLogo
              src={logoPath}
              alt="VEMS Logo"
              sx={{ mb: 0 }}
            />
            <WidgetCompanyBar />
            
            <Button
              variant="contained"
              color="info"
              size="small"
              onClick={handleStartClick}
              sx={{
                minWidth: 150,
                fontSize: '1rem',
                color: '#fff',
                mt: 5
              }}
            >
              Start now!
            </Button>
          </Box>
        </Container>
      </Box>
    </AppTheme>
  );
}
