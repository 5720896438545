// This widget is used to display the battery metrics for the simulation.

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import WidgetTimeseries from '../charts/WidgetTimeseries';
import WidgetDailyProfile from '../charts/WidgetDailyProfile';
import WidgetCandlestick from '../charts/WidgetCandlestick';
import simulationService from '../../../../../../../services/simulationService';

// Metric labels mapping
const METRIC_LABELS = {
  soc: 'State of Charge',
  p_in: 'Power In',
  p_out: 'Power Out',
};

const WidgetBatteryMetrics = ({ simulationId, scenarioId, modelInstanceId, viewType }) => {
  const [availableMetrics, setAvailableMetrics] = React.useState([]);
  const [selectedMetric, setSelectedMetric] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  // Fetch available metrics when component mounts
  React.useEffect(() => {
    const fetchAvailableMetrics = async () => {
      try {
        // We can use any of the existing endpoints to get available_fields
        const response = await simulationService.getAssetHourlyProfile(
          simulationId,
          scenarioId,
          modelInstanceId,
          'soc' // Use any metric to get the initial response
        );
        
        if (response.success && response.data.availableFields) {
          setAvailableMetrics(response.data.availableFields);
          // Set the first available metric as default
          setSelectedMetric(response.data.availableFields[0]);
        }
      } catch (error) {
        console.error('Failed to fetch available metrics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableMetrics();
  }, [simulationId, scenarioId, modelInstanceId]);

  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  if (loading) {
    return (
      <Box sx={{ mt: 2 }}>
        <Typography>Loading metrics...</Typography>
      </Box>
    );
  }

  if (!selectedMetric) {
    return null;
  }

  const renderGraph = () => {
    switch (viewType) {
      case 'timeseries':
        return (
          <WidgetTimeseries 
            simulationId={simulationId}
            scenarioId={scenarioId}
            modelInstanceId={modelInstanceId}
            metricKey={selectedMetric}
            title={`Yearly ${METRIC_LABELS[selectedMetric] || selectedMetric}`}
          />
        );
      case 'daily':
        return (
          <WidgetDailyProfile 
            simulationId={simulationId}
            scenarioId={scenarioId}
            modelInstanceId={modelInstanceId}
            metricKey={selectedMetric}
            title={`Daily Averaged ${METRIC_LABELS[selectedMetric] || selectedMetric}`}
          />
        );
      case 'monthly':
        return (
          <WidgetCandlestick 
            simulationId={simulationId}
            scenarioId={scenarioId}
            modelInstanceId={modelInstanceId}
            metricKey={selectedMetric}
            title={`Monthly Distribution of ${METRIC_LABELS[selectedMetric] || selectedMetric}`}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <Select
            value={selectedMetric}
            onChange={handleMetricChange}
          >
            {availableMetrics.map((metric) => (
              <MenuItem key={metric} value={metric}>
                {METRIC_LABELS[metric] || metric}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {renderGraph()}
    </Box>
  );
};

WidgetBatteryMetrics.propTypes = {
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modelInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  viewType: PropTypes.oneOf(['timeseries', 'daily', 'monthly']).isRequired
};

export default WidgetBatteryMetrics;