import * as React from 'react';
import { 
  TextField, 
  Stack, 
  InputAdornment,
  Typography,
  Box,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SettingsIcon from '@mui/icons-material/Settings';
import UploadProfileMethod from './UploadProfileMethod';

export default class PVAssetModule {
  static parameters = [
    { 
      name: 'creation_method', 
      label: 'Creation Method', 
      type: 'select', 
      required: true,
      defaultValue: 'file',
      options: [
        { value: 'file', label: 'Upload Profile' },
        { value: 'manual', label: 'Manual Configuration' }
      ]
    }
  ];

  static manualParameters = [
    { 
      name: 'nominal_power', 
      label: 'Nominal Power', 
      unit: 'kW', 
      type: 'number', 
      required: true 
    },
    { 
      name: 'num_panels', 
      label: 'Number of Panels', 
      unit: '', 
      type: 'number', 
      required: true 
    },
    { 
      name: 'efficiency', 
      label: 'Efficiency', 
      unit: '', 
      type: 'number', 
      required: false,
      defaultValue: 1.0
    },
    { 
      name: 'capital_cost', 
      label: 'Capital Cost', 
      unit: '€', 
      type: 'number', 
      required: false,
      defaultValue: 0
    },
    { 
      name: 'om_cost', 
      label: 'O&M Cost', 
      unit: '€/year', 
      type: 'number', 
      required: false,
      defaultValue: 0
    }
  ];

  static uploadParameters = [
    { 
      name: 'factor', 
      label: 'Scale Factor', 
      unit: '', 
      type: 'number', 
      required: false,
      defaultValue: 1.0
    },
    { 
      name: 'capital_cost', 
      label: 'Capital Cost', 
      unit: '€', 
      type: 'number', 
      required: false,
      defaultValue: 0
    },
    { 
      name: 'om_cost', 
      label: 'O&M Cost', 
      unit: '€/year', 
      type: 'number', 
      required: false,
      defaultValue: 0
    },
    { 
      name: 'profile', 
      label: 'PV Profile', 
      type: 'file', 
      required: true 
    }
  ];

  static validate(data) {
    const errors = {};
    
    if (data.creation_method === 'manual') {
      if (!data.nominal_power || data.nominal_power <= 0) {
        errors.nominal_power = 'Nominal power must be greater than 0';
      }
      if (!data.num_panels || data.num_panels <= 0) {
        errors.num_panels = 'Number of panels must be greater than 0';
      }
      if (data.efficiency !== undefined && (data.efficiency <= 0 || data.efficiency > 1)) {
        errors.efficiency = 'Efficiency must be between 0 and 1';
      }
    }

    if (data.creation_method === 'file') {
      if (data.factor !== undefined && data.factor <= 0) {
        errors.factor = 'Scale factor must be greater than 0';
      }
      if (!data.profile) {
        errors.profile = 'Profile file is required';
      }
    }

    // Common validations for both methods
    if (data.capital_cost !== undefined && data.capital_cost < 0) {
      errors.capital_cost = 'Capital cost must be greater than or equal to 0';
    }
    if (data.om_cost !== undefined && data.om_cost < 0) {
      errors.om_cost = 'O&M cost must be greater than or equal to 0';
    }

    return errors;
  }

  static transformForAPI(data) {
    if (data.creation_method === 'manual') {
      return {
        type: 'pv_weather',
        nominal_power: parseFloat(data.nominal_power),
        num_panels: parseInt(data.num_panels),
        efficiency: data.efficiency !== undefined ? parseFloat(data.efficiency) : 1.0,
        capital_cost: data.capital_cost !== undefined ? parseFloat(data.capital_cost) : 0,
        om_cost: data.om_cost !== undefined ? parseFloat(data.om_cost) : 0,
        output_type: 'power'
      };
    }

    return {
      type: 'pv_profile',
      factor: data.factor !== undefined ? parseFloat(data.factor) : 1.0,
      capital_cost: data.capital_cost !== undefined ? parseFloat(data.capital_cost) : 0,
      om_cost: data.om_cost !== undefined ? parseFloat(data.om_cost) : 0,
      output_type: 'power'
    };
  }

  static renderFields({ data, onChange, errors }) {
    const handleCreationMethodChange = (event, newMethod) => {
      if (newMethod !== null) {
        onChange('creation_method', newMethod);
        // Reset fields when switching methods
        if (newMethod === 'file') {
          onChange('nominal_power', undefined);
          onChange('num_panels', undefined);
          onChange('efficiency', undefined);
        } else {
          onChange('profile', undefined);
          onChange('factor', undefined);
        }
        onChange('capital_cost', undefined);
        onChange('om_cost', undefined);
      }
    };

    const getNumericValue = (value) => {
      if (!value && value !== 0) {
        return '';
      }
      const numValue = parseFloat(value);
      return isNaN(numValue) ? '' : numValue;
    };

    const renderNumericField = (param) => (
      <TextField
        key={param.name}
        label={param.label}
        value={getNumericValue(data[param.name])}
        onChange={(e) => {
          const newValue = e.target.value;
          onChange(param.name, newValue === '' ? undefined : newValue);
        }}
        error={!!errors?.[param.name]}
        helperText={errors?.[param.name] || (param.defaultValue !== undefined && !data[param.name] ? 
          `Default: ${param.defaultValue}` : undefined)}
        type="number"
        inputProps={{
          min: param.name === 'efficiency' ? 0 : undefined,
          max: param.name === 'efficiency' ? 1 : undefined,
          step: param.name === 'efficiency' ? "0.01" : "any"
        }}
        InputProps={{
          endAdornment: param.unit && (
            <InputAdornment position="end">
              {param.unit}
            </InputAdornment>
          )
        }}
        required={param.required}
      />
    );

    if (!data.creation_method) {
      onChange('creation_method', 'file');
    }

    return (
      <Stack spacing={3}>
        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Choose Creation Method *
          </Typography>
          <ToggleButtonGroup
            value={data.creation_method || 'file'}
            exclusive
            onChange={handleCreationMethodChange}
            fullWidth
          >
            <ToggleButton value="file">
              <Stack direction="row" spacing={1} alignItems="center">
                <UploadFileIcon />
                <Typography>Upload Profile</Typography>
              </Stack>
            </ToggleButton>
            <ToggleButton value="manual">
              <Stack direction="row" spacing={1} alignItems="center">
                <SettingsIcon />
                <Typography>Manual Configuration</Typography>
              </Stack>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {data.creation_method === 'file' && (
          <Stack spacing={2}>
            <UploadProfileMethod 
              data={data}
              onChange={onChange}
              errors={errors}
              templateName="pv_profile_template"
              required={true}
            />
            {this.uploadParameters
              .filter(param => param.type !== 'file')
              .map(renderNumericField)}
          </Stack>
        )}

        {(data.creation_method || 'file') === 'manual' && (
          <Stack spacing={2}>
            {this.manualParameters.map(renderNumericField)}
          </Stack>
        )}
      </Stack>
    );
  }
} 