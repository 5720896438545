import * as React from 'react';
import { 
  TextField, 
  Stack, 
  InputAdornment, 
  MenuItem 
} from '@mui/material';
import SOCSlider from './sliders/SOCSlider';

export default class ControllersAssetModule {
  static parameters = [
    { 
      name: 'type', 
      label: 'Controller Type', 
      type: 'select', 
      required: true,
      options: [
        { value: 'controller_base', label: 'Base Controller' },
        { value: 'controller_grid', label: 'Grid Controller' },
        { value: 'controller_pvcurtailment', label: 'PV Curtailment Controller' }
      ]
    }
  ];

  static commonParameters = [
    { 
      name: 'battery_soc_max', 
      label: 'Battery Max SOC', 
      unit: '%', 
      type: 'number', 
      required: false,
      defaultValue: 100,
      min: 0,
      max: 100
    },
    { 
      name: 'battery_soc_min', 
      label: 'Battery Min SOC', 
      unit: '%', 
      type: 'number', 
      required: false,
      defaultValue: 0,
      min: 0,
      max: 100
    },
    { 
      name: 'battery_max_p', 
      label: 'Battery Max Power', 
      unit: 'kW', 
      type: 'number', 
      required: false,
      defaultValue: 'unlimited',
      min: 0
    }
  ];

  static additionalParameters = {
    controller_base: [],  // No additional parameters for base controller
    controller_grid: [
      { 
        name: 'import_limit', 
        label: 'Import Limit', 
        unit: 'kW', 
        type: 'number', 
        required: true,
        min: 0
      },
      { 
        name: 'export_limit', 
        label: 'Export Limit', 
        unit: 'kW', 
        type: 'number', 
        required: true,
        min: 0
      }
    ],
    controller_pvcurtailment: [
      { 
        name: 'setpoint', 
        label: 'PV Setpoint', 
        unit: '€/kWh', 
        type: 'number', 
        required: false,
        defaultValue: 100,
        min: 0
      }
    ]
  };

  static validate(data) {
    const errors = {};

    // Base controller validations
    if (data.type === 'controller_base') {
      if (data.battery_soc_min !== undefined && (data.battery_soc_min < 0 || data.battery_soc_min > 100)) {
        errors.battery_soc_min = 'Battery min SOC must be between 0 and 100';
      }
      if (data.battery_soc_max !== undefined && (data.battery_soc_max < 0 || data.battery_soc_max > 100)) {
        errors.battery_soc_max = 'Battery max SOC must be between 0 and 100';
      }
      if (data.battery_soc_min >= data.battery_soc_max) {
        errors.battery_soc_min = 'Minimum SOC must be less than Maximum SOC';
        errors.battery_soc_max = 'Maximum SOC must be greater than Minimum SOC';
      }
      if (data.battery_max_p !== undefined && data.battery_max_p <= 0) {
        errors.battery_max_p = 'Battery max power must be greater than 0';
      }
    }

    // Grid controller validations
    if (data.type === 'controller_grid') {
      if (!data.import_limit) {
        errors.import_limit = 'Import limit is required';
      } else if (data.import_limit <= 0) {
        errors.import_limit = 'Import limit must be greater than 0';
      }
      if (!data.export_limit) {
        errors.export_limit = 'Export limit is required';
      } else if (data.export_limit <= 0) {
        errors.export_limit = 'Export limit must be greater than 0';
      }
    }

    // PV curtailment controller validations
    if (data.type === 'controller_pvcurtailment' && data.setpoint !== undefined) {
      if (data.setpoint < 0) {
        errors.setpoint = 'Setpoint must be greater than or equal to 0';
      }
    }

    return errors;
  }

  static transformForAPI(data) {
    const baseData = {
      type: data.type,
      battery_soc_min: data.battery_soc_min !== undefined ? parseFloat(data.battery_soc_min) : 0,
      battery_soc_max: data.battery_soc_max !== undefined ? parseFloat(data.battery_soc_max) : 100,
      battery_max_p: data.battery_max_p !== undefined ? parseFloat(data.battery_max_p) : 0
    };

    switch (data.type) {
      case 'controller_grid':
        return {
          ...baseData,
          import_limit: parseFloat(data.import_limit),
          export_limit: parseFloat(data.export_limit)
        };
      case 'controller_pvcurtailment':
        return {
          ...baseData,
          setpoint: data.setpoint !== undefined ? parseFloat(data.setpoint) : 100
        };
      default:
        return baseData;
    }
  }

  static renderFields({ data, onChange, errors }) {
    const getNumericValue = (value) => {
      if (!value && value !== 0) {
        return '';
      }
      const numValue = parseFloat(value);
      return isNaN(numValue) ? '' : numValue;
    };

    // Get type-specific parameters and combine with common parameters
    const typeSpecificParams = this.additionalParameters[data.type] || [];
    const allParams = [...this.commonParameters, ...typeSpecificParams];

    const handleSocChange = (min, max) => {
      onChange('battery_soc_min', min);
      onChange('battery_soc_max', max);
    };

    return (
      <Stack spacing={2}>
        <TextField
          select
          label="Controller Type"
          value={data.type || ''}
          onChange={(e) => onChange('type', e.target.value)}
          required
        >
          {this.parameters[0].options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <SOCSlider
          minSoc={data.battery_soc_min}
          maxSoc={data.battery_soc_max}
          onChange={handleSocChange}
          label="Battery SOC Limits"
        />

        {allParams
          .filter(param => !['battery_soc_min', 'battery_soc_max'].includes(param.name))
          .map((param) => (
            <TextField
              key={param.name}
              label={param.label}
              type="number"
              value={getNumericValue(data[param.name])}
              onChange={(e) => {
                const newValue = e.target.value;
                onChange(param.name, newValue === '' ? undefined : newValue);
              }}
              error={!!errors?.[param.name]}
              helperText={errors?.[param.name] || 
                (param.defaultValue !== undefined && !data[param.name] ? 
                  `Default: ${param.defaultValue}` : undefined)}
              InputProps={{
                endAdornment: param.unit && (
                  <InputAdornment position="end">
                    {param.unit}
                  </InputAdornment>
                )
              }}
              inputProps={{
                min: param.min,
                max: param.max,
                step: "any"
              }}
              required={param.required}
            />
          ))}
      </Stack>
    );
  }
} 