// This component is the asset library that the user can use to visualize 
// and create assets for their scenario.  

import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  Grid,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AssetCategoryList from './components/AssetCategorySideMenu';
import AssetLibraryContent from './components/AssetLibraryContent';
import ASSET_LIBRARY from '../../common-components/existing-assets/AssetService';
import Button from '@mui/material/Button';
import RetrieveDataDialog from './components/RetrieveDataDialog';

export default function AssetLibrary({ open, onClose }) {
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const assetLibraryRef = React.useRef(ASSET_LIBRARY);
  const [retrieveDialogOpen, setRetrieveDialogOpen] = React.useState(false);

  React.useEffect(() => {
    const loadAssets = async () => {
      try {
        if (selectedCategory) {
          await assetLibraryRef.current.setSelectedCategory(selectedCategory.id);
        }
      } catch (error) {
        console.error('Failed to load assets:', error);
      }
    };

    loadAssets();
  }, [selectedCategory]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleRetrieveData = () => {
    setRetrieveDialogOpen(true);
  };

  const handleRetrieveDialogClose = () => {
    setRetrieveDialogOpen(false);
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            height: '80vh',
            maxHeight: '900px'
          }
        }}
      >
        <DialogTitle sx={{ 
          m: 0, 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Typography variant="h6" component="div">
            Asset Library
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleRetrieveData}
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.primary.dark,
                },
                minWidth: '150px',
                fontSize: '0.985rem',
                padding: '6px 16px'
              }}
            >
              Retrieve Data
            </Button>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Grid container sx={{ height: '100%' }}>
            {/* Left side - Categories */}
            <Grid item xs={3} sx={{ 
              borderRight: 1, 
              borderColor: 'divider',
              height: '100%',
              overflow: 'auto'
            }}>
              <AssetCategoryList 
                selectedCategory={selectedCategory}
                onCategorySelect={handleCategorySelect}
              />
            </Grid>
            
            {/* Right side - Asset Library Content */}
            <Grid item xs={9} sx={{ height: '100%', overflow: 'auto' }}>
              <AssetLibraryContent 
                category={selectedCategory}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <RetrieveDataDialog 
        open={retrieveDialogOpen}
        onClose={handleRetrieveDialogClose}
      />
    </>
  );
}

AssetLibrary.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
