// Utility functions for token management
export function decodeJWT(token) {
  try {
    // Split the token and get the payload
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
}

export function getTokenExpiryTime(token) {
  const decoded = decodeJWT(token);
  return decoded ? decoded.exp * 1000 : null; // Convert to milliseconds
}

export function shouldRefreshToken(token, refreshThreshold = 1 * 60 * 1000) { // 1 minute before expiry
  const expiryTime = getTokenExpiryTime(token);
  if (!expiryTime) return false;
  
  return Date.now() + refreshThreshold > expiryTime;
} 