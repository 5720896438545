// This component is the existing simulation page, where you can see the details of a simulation and run it or run it again.

import * as React from 'react';
import { alpha } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import WidgetMobileNavbar from '../../common-components/WidgetMobileNavbar';
import Header from '../../common-components/Header';
import SideMenu from '../../common-components/SideMenu';
import AppTheme from '../../../shared-theme/AppTheme';
import ExistingSimulationGrid from './layout/ExistingSimulationGrid';
import simulationService from '../../../../services/simulationService';
import { Alert, CircularProgress } from '@mui/material';
import {
  chartsCustomizations,
  dataTableCustomizations,
  nestedTreeCustomizations,
} from '../../user-home/theme_customizations';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataTableCustomizations,
  ...nestedTreeCustomizations,
};

export default function ExistingSimulation(props) {
  const { simulationId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [simulationData, setSimulationData] = React.useState(null);
  
  // Add polling interval reference
  const pollingIntervalRef = React.useRef(null);

  // Add function to fetch simulation data
  const fetchSimulationData = React.useCallback(async () => {
    try {
      const result = await simulationService.getSimulation(simulationId);

      if (result.success) {
        setSimulationData({
          simulationName: result.data.name,
          status: result.data.status,
          createdAt: new Date(result.data.created_at).toLocaleString(),
          startedAt: result.data.started_at ? new Date(result.data.started_at).toLocaleString() : null,
          completedAt: result.data.completed_at ? new Date(result.data.completed_at).toLocaleString() : null,
          errorMessage: result.data.error_message
        });

        // Stop polling if simulation is completed or has error
        if (result.data.status.toLowerCase() === 'completed' || 
            result.data.status.toLowerCase() === 'error') {
          clearInterval(pollingIntervalRef.current);
        }

        setError(null);
      } else {
        setError(result.error);
        if (result.error === 'Simulation not found') {
          setTimeout(() => navigate('/simulator'), 3000);
        }
      }
    } catch (error) {
      console.error('Error fetching simulation:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [simulationId, navigate]);

  // Set up polling when simulation is running
  React.useEffect(() => {
    if (simulationData?.status.toLowerCase() === 'running') {
      // Poll every 5 seconds while running
      pollingIntervalRef.current = setInterval(fetchSimulationData, 5000);
    }

    return () => {
      // Cleanup interval on unmount or when status changes
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [simulationData?.status, fetchSimulationData]);

  // Initial fetch
  React.useEffect(() => {
    fetchSimulationData();
  }, [fetchSimulationData]);

  // Add function to handle simulation start
  const handleSimulationStart = React.useCallback(() => {
    // Immediately update local state to show running status
    setSimulationData(prev => ({
      ...prev,
      status: 'running',
      startedAt: new Date().toLocaleString()
    }));
    
    // Start polling for updates
    pollingIntervalRef.current = setInterval(fetchSimulationData, 5000);
  }, [fetchSimulationData]);

  if (loading) {
    return (
      <AppTheme {...props} themeComponents={xThemeComponents}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </AppTheme>
    );
  }

  if (error) {
    return (
      <AppTheme {...props} themeComponents={xThemeComponents}>
        <Box sx={{ p: 3 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </AppTheme>
    );
  }

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <WidgetMobileNavbar />
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              px: { xs: 3, md: 4 },
              pb: 5,
              mt: { xs: 8, md: 0 },
              width: '100%',
              maxWidth: '100%',
              flexGrow: 1,
              overflow: 'auto',
            }}
          >
            <Box sx={{ width: '100%', maxWidth: '1700px' }}>
              <Header />
              {simulationData && (
                <ExistingSimulationGrid 
                  simulationData={simulationData}
                  onSimulationStart={handleSimulationStart}
                />
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
