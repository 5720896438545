import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AppTheme from '../../shared-theme/AppTheme';
import AssetsList from './components/AssetsList';
import DropZone from './components/DropZone';
import ASSET_LIBRARY from '../../user-interface/common-components/existing-assets/AssetService';
import { ASSET_CATEGORIES } from '../../user-interface/common-components/existing-assets/AssetCategories';
import simulationService from '../../../services/simulationService';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ColorModeIconDropdown from '../../shared-theme/ColorModeIconDropdown';
import TopologyVisualization from './components/TopologyVisualization';

export default function BuildPersonalDigitalTwin() {
  const navigate = useNavigate();
  const [placedAssets, setPlacedAssets] = React.useState([]);
  const [modelInstances, setModelInstances] = React.useState({});
  const [loadingInstances, setLoadingInstances] = React.useState({});
  const [expandedAssets, setExpandedAssets] = React.useState({});

  const handleAssetExpand = (assetId) => {
    setExpandedAssets(prev => ({
      ...prev,
      [assetId]: !prev[assetId]
    }));
  };

  const fetchModelInstances = async (assetType) => {
    const category = ASSET_CATEGORIES.find(cat => cat.id === assetType.toLowerCase());
    if (!category) return;

    try {
      setLoadingInstances(prev => ({ ...prev, [assetType]: true }));
      
      // Use ASSET_LIBRARY directly
      await ASSET_LIBRARY.setSelectedCategory(category.id);
      const libraryAssets = await ASSET_LIBRARY.getAssets();
      
      setModelInstances(prev => ({ 
        ...prev, 
        [assetType]: libraryAssets[category.id] || []
      }));
    } catch (error) {
      console.error(`Error fetching ${category.id} instances:`, error);
    } finally {
      setLoadingInstances(prev => ({ ...prev, [assetType]: false }));
    }
  };

  const handleAssetDrop = async (asset) => {
    if (!modelInstances[asset.type]) {
      await fetchModelInstances(asset.type);
    }

    const newAssetId = Date.now();
    
    setExpandedAssets(prev => ({
      ...prev,
      [newAssetId]: true
    }));

    setPlacedAssets((prev) => [...prev, { 
      ...asset, 
      id: newAssetId,
      selectedInstance: null
    }]);
  };

  const handleAssetInstanceSelect = (assetId, instance) => {
    setPlacedAssets(prev => prev.map(asset => 
      asset.id === assetId 
        ? { 
            ...asset, 
            selectedInstance: instance,
          }
        : asset
    ));
    
    // Automatically collapse the asset after selection
    setExpandedAssets(prev => ({
      ...prev,
      [assetId]: false
    }));
  };

  const handleAssetRemove = (assetId) => {
    setPlacedAssets((prev) => prev.filter((asset) => asset.id !== assetId));
  };

  const handleNewAssetCreated = async (newAsset, assetType) => {
    try {
      // Transform the asset data for API
      const modelData = {
        ...newAsset,
        type: assetType.toLowerCase()
      };

      // Create the model instance via API
      const response = await simulationService.createModelInstance(modelData);
      
      if (!response.success) {
        throw new Error(response.error);
      }

      console.log('New asset created:', response.data);
      
      // Refresh the model instances for this asset type
      await fetchModelInstances(assetType);
    } catch (error) {
      console.error('Failed to create asset:', error);
      // You might want to add error handling UI here
    }
  };

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <DndProvider backend={HTML5Backend}>
        <Box
          sx={(theme) => ({
            width: '100%',
            minHeight: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundImage:
              'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
            ...theme.applyStyles('dark', {
              backgroundImage:
                'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
            }),
          })}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
              zIndex: 1000
            }}
          >
            <ColorModeIconDropdown />
          </Box>

          <Container maxWidth="xxl">
            <Box
              sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                py: 4,
                px: { xs: 2, md: 4, lg: 8 },
              }}
            >
              <Typography 
                variant="h2" 
                component="h1" 
                gutterBottom
                sx={{ 
                  textAlign: 'center',
                  mb: 6,
                  fontWeight: 600
                }}
              >
                Build Your Digital Twin
              </Typography>
              
              <Grid container spacing={4}>
                {/* Available Assets */}
                <Grid item xs={12} md={2}>
                  <Paper 
                    elevation={0}
                    sx={{ 
                      p: 3,
                      height: '100%',
                      backgroundColor: 'background.paper',
                      mt: { xs: 0, md: 0 }
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Available Assets
                    </Typography>
                    <AssetsList />
                  </Paper>
                </Grid>

                {/* Main Content Area */}
                <Grid item xs={12} md={10}>
                  <Grid container spacing={4} sx={{ mt: { xs: -4, md: -4 } }}>
                    {/* Drop Zone */}
                    <Grid item xs={12} md={7}>
                      <Paper 
                        elevation={0}
                        sx={{ 
                          p: 3,
                          minHeight: 700,
                          backgroundColor: 'background.paper',
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Assemble your Current Situation
                        </Typography>
                        <DropZone 
                          onDrop={handleAssetDrop}
                          placedAssets={placedAssets}
                          onAssetRemove={handleAssetRemove}
                          onAssetInstanceSelect={handleAssetInstanceSelect}
                          modelInstances={modelInstances}
                          loadingInstances={loadingInstances}
                          expandedAssets={expandedAssets}
                          onAssetExpand={handleAssetExpand}
                          onNewAssetCreated={handleNewAssetCreated}
                        />
                      </Paper>
                    </Grid>

                    {/* Topology Visualization */}
                    <Grid item xs={12} md={5}>
                      <Paper 
                        elevation={0}
                        sx={{ 
                          p: 3,
                          backgroundColor: 'background.paper',
                          height: '100%',
                          minHeight: 700,
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          System Topology
                        </Typography>
                        <TopologyVisualization placedAssets={placedAssets} />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Optimize Button Container */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 'auto',
                  pb: 4,
                }}
              >
                <Button
                  variant="contained"
                  color="info"
                  size="small"
                  onClick={() => {
                    navigate('/loading-digital-twin-creation');
                  }}
                  sx={{
                    minWidth: 180,
                    fontSize: '1.1rem',
                    color: '#fff',
                    mt: 5,
                    py: 1.2,
                    px: 4
                  }}
                >
                  Optimize Now !
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </DndProvider>
    </AppTheme>
  );
}
