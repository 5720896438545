import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';
import { ASSET_CLASSES, ASSET_TYPE_LABELS, getAssetIcon } from '../existing-assets/AssetCategories';

// Asset types and positions
const ASSET_POSITIONS = {
  [ASSET_CLASSES.CONTROLLER]: { x: 0, y: 0 }, // Center
  [ASSET_CLASSES.PV]: { x: 0, y: -150 }, // Above
  [ASSET_CLASSES.STORAGE]: { x: 0, y: 150 }, // Below
  [ASSET_CLASSES.TARIFF]: { x: -150, y: 0 }, // Left
  [ASSET_CLASSES.LOAD]: { x: 150, y: 0 }, // Right
};

const MULTI_ASSET_SPACING = 140;

const shouldConnect = (sourceType, targetType) => {
  const getBaseType = (type) => {
    if (type.startsWith('controller')) return ASSET_CLASSES.CONTROLLER;
    if (type.startsWith('pv')) return ASSET_CLASSES.PV;
    if (type.startsWith('battery')) return ASSET_CLASSES.STORAGE;
    if (type.startsWith('tariff')) return ASSET_CLASSES.TARIFF;
    if (type.startsWith('load')) return ASSET_CLASSES.LOAD;
    return type;
  };
  
  const baseSourceType = getBaseType(sourceType);
  const baseTargetType = getBaseType(targetType);
  
  return baseSourceType === ASSET_CLASSES.CONTROLLER || baseTargetType === ASSET_CLASSES.CONTROLLER;
};

const AssetNode = styled(motion.div)(({ theme, selected }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  width: 120,
  minHeight: 100,
  position: 'relative',
  border: selected ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.divider}`,
  cursor: 'pointer',
  textAlign: 'center',
  '& > span': {
    width: '100%',
    textAlign: 'center',
    padding: `0 ${theme.spacing(0.5)}`,
    fontSize: '0.875rem',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    maxWidth: '100%',
  },
  '& .MuiTypography-caption': {
    fontSize: '0.75rem',
    lineHeight: 1.2,
    display: 'block',
    width: '100%',
    padding: `0 ${theme.spacing(0.5)}`,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    hyphens: 'auto',
    maxWidth: '100%',
    whiteSpace: 'normal',
    '&:last-child': {
      marginBottom: theme.spacing(0.5),
      fontSize: '0.7rem',
    }
  },
  '& .MuiSvgIcon-root': {
    transition: 'color 0.2s ease-in-out',
    fontSize: '2rem',
    color: selected ? theme.palette.primary.main : 'inherit',
  },
  ...(theme.palette.mode === 'dark' && {
    border: selected ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.grey[700]}`,
    boxShadow: `0 0 10px 0 ${theme.palette.grey[900]}`,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 15px 0 ${theme.palette.grey[800]}`,
    },
  }),
  '&:hover': {
    borderColor: theme.palette.primary.main,
    transform: 'translateY(-2px)',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    }
  },
  transition: 'all 0.2s ease-in-out',
}));

const getFlowDirection = (startType, endType) => {
  const getBaseType = (type) => {
    if (type.startsWith('controller')) return ASSET_CLASSES.CONTROLLER;
    if (type.startsWith('pv')) return ASSET_CLASSES.PV;
    if (type.startsWith('battery')) return ASSET_CLASSES.STORAGE;
    if (type.startsWith('tariff')) return ASSET_CLASSES.TARIFF;
    if (type.startsWith('load')) return ASSET_CLASSES.LOAD;
    return type;
  };

  const baseSourceType = getBaseType(startType);
  const baseTargetType = getBaseType(endType);

  // PV connection should always flow TO controller
  if ((baseSourceType === ASSET_CLASSES.PV && baseTargetType === ASSET_CLASSES.CONTROLLER) ||
      (baseTargetType === ASSET_CLASSES.PV && baseSourceType === ASSET_CLASSES.CONTROLLER)) {
    // Return 'reverse' if PV is the end point
    return baseTargetType === ASSET_CLASSES.PV ? 'reverse' : 'forward';
  }
  
  // Controller to load is one way
  if (baseSourceType === ASSET_CLASSES.CONTROLLER && baseTargetType === ASSET_CLASSES.LOAD) {
    return 'forward';
  }
  
  // Battery and Tariff connections are bidirectional
  if ((baseSourceType === ASSET_CLASSES.STORAGE || baseSourceType === ASSET_CLASSES.TARIFF) &&
      baseTargetType === ASSET_CLASSES.CONTROLLER) {
    return 'bidirectional';
  }
  if (baseSourceType === ASSET_CLASSES.CONTROLLER && 
      (baseTargetType === ASSET_CLASSES.STORAGE || baseTargetType === ASSET_CLASSES.TARIFF)) {
    return 'bidirectional';
  }
  
  return 'forward'; // default fallback
};

const Connection = ({ start, end, startType, endType }) => {
  const flowDirection = getFlowDirection(startType, endType);
  const pathDef = flowDirection === 'reverse'
    ? `M ${end.x},${end.y} Q ${(start.x + end.x) / 2},${(start.y + end.y) / 2} ${start.x},${start.y}`
    : `M ${start.x},${start.y} Q ${(start.x + end.x) / 2},${(start.y + end.y) / 2} ${end.x},${end.y}`;

  return (
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }}
    >
      {/* Background static path */}
      <motion.path
        d={pathDef}
        stroke="currentColor"
        strokeWidth={2}
        strokeOpacity={0.3}
        fill="none"
      />

      {/* Animated pulsing paths */}
      {flowDirection === 'bidirectional' ? (
        <>
          {/* Forward pulse */}
          <motion.path
            d={pathDef}
            stroke="currentColor"
            strokeWidth={2}
            fill="none"
            strokeDasharray="5,5"
            initial={{ pathLength: 0, strokeOpacity: 0.8 }}
            animate={{
              pathLength: [0, 1],
              strokeOpacity: [0.8, 0],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatDelay: 1.5,
              ease: "linear",
            }}
          />
          {/* Backward pulse */}
          <motion.path
            d={`M ${end.x},${end.y} Q ${(start.x + end.x) / 2},${(start.y + end.y) / 2} ${start.x},${start.y}`}
            stroke="currentColor"
            strokeWidth={2}
            fill="none"
            strokeDasharray="5,5"
            initial={{ pathLength: 0, strokeOpacity: 0.8 }}
            animate={{
              pathLength: [0, 1],
              strokeOpacity: [0.8, 0],
            }}
            transition={{
              duration: 1.5,
              delay: 1.5,
              repeat: Infinity,
              repeatDelay: 1.5,
              ease: "linear",
            }}
          />
        </>
      ) : (
        // One-way pulse
        <motion.path
          d={pathDef}
          stroke="currentColor"
          strokeWidth={2}
          fill="none"
          strokeDasharray="5,5"
          initial={{ pathLength: 0, strokeOpacity: 0.8 }}
          animate={{
            pathLength: [0, 1],
            strokeOpacity: [0.8, 0],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "linear",
          }}
        />
      )}
    </svg>
  );
};

Connection.propTypes = {
  start: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  end: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  startType: PropTypes.string.isRequired,
  endType: PropTypes.string.isRequired,
};

const WidgetExistingScenarioTopology = ({ assets, selectedAssetId, onAssetSelect }) => {
  const boxRef = React.useRef(null);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  const resizeObserverRef = React.useRef(null);

  React.useEffect(() => {
    if (boxRef.current) {
      const updateDimensions = () => {
        setDimensions({
          width: boxRef.current.offsetWidth,
          height: boxRef.current.offsetHeight
        });
      };

      // Initial dimensions
      updateDimensions();

      // Create ResizeObserver to watch for container size changes
      resizeObserverRef.current = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === boxRef.current) {
            updateDimensions();
          }
        }
      });

      // Start observing the container
      resizeObserverRef.current.observe(boxRef.current);

      // Also keep the window resize listener for safety
      window.addEventListener('resize', updateDimensions);

      return () => {
        // Cleanup
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }
        window.removeEventListener('resize', updateDimensions);
      };
    }
  }, []);

  const calculateNodePositions = (assets) => {
    if (!dimensions.width || !dimensions.height || assets.length === 0) return [];
    
    const centerX = dimensions.width / 2;
    const centerY = dimensions.height / 2;

    const assetsByType = assets.reduce((acc, asset) => {
      const baseType = asset.type.startsWith('controller') ? ASSET_CLASSES.CONTROLLER
        : asset.type.startsWith('pv') ? ASSET_CLASSES.PV
        : asset.type.startsWith('battery') ? ASSET_CLASSES.STORAGE
        : asset.type.startsWith('tariff') ? ASSET_CLASSES.TARIFF
        : asset.type.startsWith('load') ? ASSET_CLASSES.LOAD
        : asset.type;

      if (ASSET_POSITIONS[baseType]) {
        acc[baseType] = acc[baseType] || [];
        acc[baseType].push(asset);
      }
      return acc;
    }, {});

    const defaultController = {
      id: 'default-controller',
      type: ASSET_CLASSES.CONTROLLER,
      name: 'Controller',
      icon: <SettingsIcon color="primary" />,
    };

    const positionedAssets = [];

    if (assets.length > 0) {
      const controller = assetsByType[ASSET_CLASSES.CONTROLLER]?.[0] || defaultController;
      positionedAssets.push({
        ...controller,
        x: centerX + ASSET_POSITIONS[ASSET_CLASSES.CONTROLLER].x,
        y: centerY + ASSET_POSITIONS[ASSET_CLASSES.CONTROLLER].y,
      });
    }

    Object.entries(assetsByType).forEach(([type, typeAssets]) => {
      if (type !== ASSET_CLASSES.CONTROLLER && ASSET_POSITIONS[type]) {
        const basePosition = ASSET_POSITIONS[type];
        const totalWidth = (typeAssets.length - 1) * MULTI_ASSET_SPACING;
        const startX = -totalWidth / 2;

        typeAssets.forEach((asset, index) => {
          const offset = index * MULTI_ASSET_SPACING;
          positionedAssets.push({
            ...asset,
            x: centerX + basePosition.x + startX + offset,
            y: centerY + basePosition.y,
          });
        });
      }
    });

    return positionedAssets;
  };

  // Force recalculation of node positions when dimensions change
  const nodes = React.useMemo(() => 
    calculateNodePositions(assets),
    [assets, dimensions.width, dimensions.height]
  );

  const handleAssetClick = (assetId) => {
    onAssetSelect(selectedAssetId === assetId ? null : assetId);
  };

  return (
    <Box
      ref={boxRef}
      sx={{
        width: '100%',
        height: 450, // Reduced height for better fit
        position: 'relative',
        overflow: 'hidden',
        bgcolor: 'background.default',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {dimensions.width > 0 && (
        <motion.div
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
          layout // This tells Framer Motion to animate layout changes
          transition={{ duration: 0.3 }} // Smooth transition for layout changes
        >
          {assets.length === 0 ? (
            <Typography 
              color="text.secondary"
              variant="body1"
              sx={{ textAlign: 'center' }}
            >
              No assets to visualize
            </Typography>
          ) : (
            <>
              {nodes.map((node, i) => 
                nodes.slice(i + 1).map((otherNode) => {
                  if (shouldConnect(node.type, otherNode.type)) {
                    return (
                      <Connection
                        key={`${node.id}-${otherNode.id}`}
                        start={{ x: node.x, y: node.y }}
                        end={{ x: otherNode.x, y: otherNode.y }}
                        startType={node.type}
                        endType={otherNode.type}
                      />
                    );
                  }
                  return null;
                }).filter(Boolean)
              )}

              {nodes.map((node) => (
                <AssetNode
                  key={node.id}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  onClick={() => handleAssetClick(node.id)}
                  selected={selectedAssetId === node.id}
                  style={{
                    position: 'absolute',
                    left: node.x - 60,
                    top: node.y - 60,
                  }}
                >
                  {node.icon || (getAssetIcon(node.type) ? React.createElement(getAssetIcon(node.type)) : <SettingsIcon color="primary" />)}
                  <span>{node.name}</span>
                  {node.selectedInstance && (
                    <Stack spacing={0.5} alignItems="center">
                      <Typography variant="caption" color="text.secondary">
                        {ASSET_TYPE_LABELS[node.selectedInstance.type] || node.selectedInstance.type}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {node.selectedInstance.name}
                      </Typography>
                    </Stack>
                  )}
                </AssetNode>
              ))}
            </>
          )}
        </motion.div>
      )}
    </Box>
  );
};

WidgetExistingScenarioTopology.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.node,
    type: PropTypes.string.isRequired,
    selectedInstance: PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  })).isRequired,
  selectedAssetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAssetSelect: PropTypes.func.isRequired,
};

export default WidgetExistingScenarioTopology;
