// This widget is used to display the Sankey diagram for energy flows in the simulation.

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ResponsiveSankey } from '@nivo/sankey';

const WidgetSankey = ({ data }) => {
  // Helper function to prepare Sankey data from metrics
  const prepareSankeyData = (data) => {
    const nodes = [      
      { 
        id: 'grid_import', 
        label: 'Grid Import',
        total: data.consumption_metrics?.grid_to_load + (data.storage_metrics?.grid_to_battery || 0)
      },
      { 
        id: 'grid_export', 
        label: 'Grid Export',
        total: (data.production_metrics?.pv_to_grid || 0) + (data.storage_metrics?.battery_to_grid || 0)
      },
      { 
        id: 'pv', 
        label: 'PV System',
        total: data.production_metrics?.total_pv_energy || 0
      },
      { 
        id: 'battery', 
        label: 'Battery',
        total: data.storage_metrics?.total_battery_discharge || 0
      },
      { 
        id: 'load', 
        label: 'Load',
        total: data.consumption_metrics?.total_load_energy || 0
      },
      { 
        id: 'curtailed', 
        label: 'PV Curtailed',
        total: data.production_metrics?.pv_curtailed || 0
      },
      { 
        id: 'shed', 
        label: 'Load Shed',
        total: data.consumption_metrics?.load_shed || 0
      }
    ];
    const links = [];

    // Helper to add a link if the value exists and is greater than zero
    const addLink = (source, target, metricKey) => {
      const metricSection = metricKey.includes('pv_') ? 'production' :
                           metricKey.includes('battery_') ? 'storage' : 'consumption';
      const value = data[`${metricSection}_metrics`]?.[metricKey];
      
      if (value !== null && value !== undefined && value > 0) {
        links.push({
          source,
          target,
          value: Math.abs(value)
        });
      }
    };

    // Add energy flow links
    if (data.production_metrics) {
      addLink('pv', 'load', 'pv_to_load');
      addLink('pv', 'battery', 'pv_to_battery');
      addLink('pv', 'grid_export', 'pv_to_grid');
      
      // Add PV curtailment if it exists and is non-zero
      const pvCurtailed = data.production_metrics.pv_curtailed;
      if (pvCurtailed !== null && pvCurtailed !== undefined && pvCurtailed > 0) {
        links.push({
          source: 'pv',
          target: 'curtailed',
          value: Math.abs(pvCurtailed)
        });
      }
    }

    if (data.storage_metrics) {
      addLink('battery', 'grid_export', 'battery_to_grid');
      addLink('grid_import', 'battery', 'grid_to_battery');
    }

    if (data.consumption_metrics) {
      addLink('grid_import', 'load', 'grid_to_load');
      addLink('battery', 'load', 'battery_to_load');
      
      // Add Load shed if it exists and is non-zero
      const loadShed = data.consumption_metrics.load_shed;
      if (loadShed !== null && loadShed !== undefined && loadShed > 0) {
        links.push({
          source: 'load',
          target: 'shed',
          value: Math.abs(loadShed)
        });
      }
    }

    // Only return nodes that have connections
    const usedNodeIds = new Set([
      ...links.map(link => link.source),
      ...links.map(link => link.target)
    ]);
    
    return { 
      nodes: nodes.filter(node => usedNodeIds.has(node.id)),
      links 
    };
  };

  const sankeyData = prepareSankeyData(data);

  if (!sankeyData.links.length || !sankeyData.nodes.length) {
    return (
      <Box sx={{ 
        height: 400, 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Typography color="text.secondary">
          No energy flows to display
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      height: 400,
      borderRadius: 1,
      border: 1,
      borderColor: 'divider',
      p: 2,
      boxShadow: 1
    }}>
      <Typography variant="h6" gutterBottom color="text.primary" sx={{ ml: 2 }}>
        Energy flow 
      </Typography>
      <ResponsiveSankey
        data={sankeyData}
        margin={{ top: 20, right: 100, bottom: 40, left: 100 }}
        align="justify"
        colors={node => {
          const colorMap = {
            'pv': '#FFA726',
            'grid_import': '#42A5F5',
            'grid_export': '#66BB6A',
            'battery': '#AB47BC',
            'load': '#FF7043',
            'curtailed': '#FFCA28',
            'shed': '#EC407A'
          };
          return colorMap[node.id] || '#9E9E9E';
        }}
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.35}
        nodeThickness={18}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.8]]
        }}
        linkBlendMode="normal"
        linkOpacity={0.5}
        linkHoverOthersOpacity={0.15}
        linkContract={3}
        enableLinkGradient={true}
        linkColor={{
          from: 'source.color'
        }}
        labelPosition="outside"
        labelOrientation="horizontal"
        labelPadding={16}
        theme={{
          labels: {
            text: {
              fontSize: 14,
              fontWeight: 'bold',
              fill: 'currentColor'
            }
          },
          tooltip: {
            container: {
              background: 'var(--mui-palette-background-paper)',
              color: 'var(--mui-palette-text-primary)',
              fontSize: '14px'
            }
          }
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1]]
        }}
        nodeId="id"
        label={node => node.label}
        sort={(a, b) => {
          // Define columns for layout
          const columns = {
            pv: 0,          // Left column
            grid_import: 0,  // Left column
            battery: 1,      // Center column
            load: 2,         // Right column
            grid_export: 2,  // Right column
            curtailed: 3,    // Far right column
            shed: 3          // Far right column
          };
          
          // First sort by column
          const columnDiff = columns[a.id] - columns[b.id];
          if (columnDiff !== 0) return columnDiff;
          
          // Within same column, define vertical ordering
          const verticalOrder = {
            // Left column (top to bottom)
            pv: 0,
            grid_import: 1,
            // Right column (top to bottom)
            load: 0,
            grid_export: 1,
            // Far right column (top to bottom)
            curtailed: 0,
            shed: 1
          };
          
          return (verticalOrder[a.id] || 0) - (verticalOrder[b.id] || 0);
        }}
        valueFormat=">-.0f"
        nodeTooltip={({ node }) => (
          <Box sx={{ 
            bgcolor: 'background.paper',
            p: 1,
            boxShadow: 1,
            borderRadius: 1
          }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
              {node.label || node.id}
            </Typography>
            <Typography variant="body2">
              Total: {parseInt(node.total).toLocaleString()} kWh
            </Typography>
          </Box>
        )}
        linkTooltip={({ link }) => {
          // Calculate percentages
          const sourcePercentage = ((link.value / link.source.total) * 100).toFixed(1);
          const targetPercentage = ((link.value / link.target.total) * 100).toFixed(1);
          
          return (
            <Box sx={{ 
              bgcolor: 'background.paper',
              p: 1,
              boxShadow: 1,
              borderRadius: 1
            }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                {link.source.label || link.source.id} → {link.target.label || link.target.id}
              </Typography>
              <Typography variant="body2">
                {parseInt(link.value).toLocaleString()} kWh
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '0.85em' }}>
                {sourcePercentage}% of {link.source.label} | {targetPercentage}% of {link.target.label}
              </Typography>
            </Box>
          );
        }}
      />
    </Box>
  );
};

WidgetSankey.propTypes = {
  data: PropTypes.object.isRequired
};

export default WidgetSankey; 