import api from './api';
import dayjs from 'dayjs';

const simulationService = {
  // Get list of simulations with optional filters
  getSimulations: async (filters = {}) => {
    try {
      const queryParams = new URLSearchParams();
      if (filters.status) queryParams.append('status', filters.status);
      if (filters.page) queryParams.append('page', filters.page);
      if (filters.per_page) queryParams.append('per_page', filters.per_page);

      const response = await api.get(`/simulations?${queryParams}`);
      return {
        success: true,
        data: {
          simulations: response.data.simulations,
          currentPage: response.data.current_page,
          totalPages: response.data.pages,
          total: response.data.total
        }
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch simulations'
      };
    }
  },

  // Get a specific simulation by ID
  getSimulation: async (simulationId) => {
    try {
      const response = await api.get(`/simulations/${simulationId}`);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      if (error.response?.status === 404) {
        return {
          success: false,
          error: 'Simulation not found'
        };
      }
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch simulation'
      };
    }
  },

  // Create a new simulation
  createSimulation: async (simulationData) => {
    try {
      // Format dates to match required API format, using UTC dates
      const formattedData = {
        ...simulationData,
        start_date: simulationData.start_date 
          ? dayjs.utc(simulationData.start_date).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        end_time: simulationData.end_time
          ? dayjs.utc(simulationData.end_time).format('YYYY-MM-DD HH:mm:ss')
          : undefined
      };

      const response = await api.post('/simulations/create', formattedData);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to create simulation'
      };
    }
  },

  // Create a new scenario
  createScenario: async (scenarioData) => {
    try {
      const response = await api.post('/scenarios/create', scenarioData);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to create scenario'
      };
    }
  },

  // Associate model instances with a scenario
  associateModels: async (scenarioId, modelInstanceIds) => {
    try {
      const response = await api.post(`/scenarios/${scenarioId}/models/associate`, {
        model_instance_ids: modelInstanceIds
      });
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to associate model instances'
      };
    }
  },

  // Update simulation details
  updateSimulation: async (simulationId, updateData) => {
    try {
      // Convert dates to ISO format if they exist
      const formattedData = { ...updateData };
      if (updateData.start_date) {
        formattedData.start_date = updateData.start_date.toISOString();
      }
      if (updateData.end_time) {
        formattedData.end_time = updateData.end_time.toISOString();
      }

      const response = await api.patch(`/simulations/${simulationId}`, formattedData);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to update simulation'
      };
    }
  },

  // Delete a simulation
  deleteSimulation: async (simulationId) => {
    try {
      const response = await api.delete(`/simulations/${simulationId}`);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to delete simulation'
      };
    }
  },

  // Update scenario details
  updateScenario: async (scenarioId, updateData) => {
    try {
      const response = await api.patch(`/scenarios/${scenarioId}`, updateData);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to update scenario'
      };
    }
  },

  // Run a simulation
  runSimulation: async (simulationId) => {
    try {
      const response = await api.post(`/simulations/${simulationId}/run`);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to run simulation'
      };
    }
  },

  // Delete a model instance
  deleteModelInstance: async (instanceId) => {
    try {
      const response = await api.delete(`/model-instances/${instanceId}`);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to delete model instance'
      };
    }
  },

  // Delete a scenario
  deleteScenario: async (scenarioId) => {
    try {
      const response = await api.delete(`/scenarios/${scenarioId}`);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to delete scenario'
      };
    }
  },

  // Add new method for creating model instances
  createModelInstance: async (modelData) => {
    try {
      const response = await api.post('/model-instances/create', modelData);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to create model instance'
      };
    }
  },

  // Add this method to the simulationService object
  getSimulationScenarios: async (simulationId) => {
    try {
      const response = await api.get(`/simulations/${simulationId}/scenarios`);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch simulation scenarios'
      };
    }
  },

  // Get daily profile data for a specific model instance and metric
  getAssetDailyProfile: async (simulationId, scenarioId, modelInstanceId, metricKey) => {
    try {
      const response = await api.get(
        `/simulations/${String(simulationId)}/scenarios/${String(scenarioId)}/daily-profile?model_instance_id=${String(modelInstanceId)}`
      );
      
      // Check if the requested metric exists in available fields
      if (!response.data.available_fields.includes(metricKey)) {
        throw new Error(`Metric ${metricKey} not available`);
      }

      // Get the values for the specific metric
      const values = response.data.data[metricKey].map(item => item.value);
      
      return {
        success: true,
        data: {
          values,
          modelInstance: response.data.model_instance,
          availableFields: response.data.available_fields
        }
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch daily profile data'
      };
    }
  },

  getAssetHourlyProfile: async (simulationId, scenarioId, modelInstanceId, metricKey) => {
    try {
      const response = await api.get(
        `/simulations/${String(simulationId)}/scenarios/${String(scenarioId)}/hourly-profile?model_instance_id=${String(modelInstanceId)}`
      );
      
      // Check if the requested metric exists in available fields
      if (!response.data.available_fields.includes(metricKey)) {
        throw new Error(`Metric ${metricKey} not available`);
      }

      // Get the series data for the specific metric
      const series = response.data.data[metricKey].map(item => ({
        x: new Date(item.timestamp),
        y: item.value
      }));
      
      return {
        success: true,
        data: {
          series,
          modelInstance: response.data.model_instance,
          availableFields: response.data.available_fields
        }
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch hourly profile data'
      };
    }
  },

  getAssetCandlestick: async (simulationId, scenarioId, modelInstanceId, metricKey) => {
    try {
      const response = await api.get(
        `/simulations/${String(simulationId)}/scenarios/${String(scenarioId)}/candlestick?model_instance_id=${String(modelInstanceId)}`
      );
      
      // Check if the requested metric exists in available fields
      if (!response.data.available_fields.includes(metricKey)) {
        throw new Error(`Metric ${metricKey} not available`);
      }

      return {
        success: true,
        data: {
          values: response.data.data[metricKey],
          modelInstance: response.data.model_instance,
          availableFields: response.data.available_fields
        }
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch candlestick data'
      };
    }
  },

  // Add this method to simulationService
  getAssetHeatmap: async (simulationId, scenarioId, modelInstanceId, metricKey) => {
    try {
      const response = await api.get(
        `/simulations/${String(simulationId)}/scenarios/${String(scenarioId)}/heatmap?model_instance_id=${String(modelInstanceId)}`
      );
      
      // Check if the requested metric exists in available fields
      if (!response.data.available_fields.includes(metricKey)) {
        throw new Error(`Metric ${metricKey} not available`);
      }

      return {
        success: true,
        data: {
          data: response.data.data,
          modelInstance: response.data.model_instance,
          availableFields: response.data.available_fields
        }
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch heatmap data'
      };
    }
  },

  // Add this method to simulationService
  getScenarioResults: async (simulationId, scenarioId) => {
    try {
      const response = await api.get(
        `/simulations/${String(simulationId)}/scenarios/${String(scenarioId)}/results`
      );
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch scenario results'
      };
    }
  },

  getUserStoredData: async (params = {}) => {
    try {
      const queryParams = new URLSearchParams();
      if (params.ean_code) queryParams.append('ean_code', params.ean_code);
      if (params.status) queryParams.append('status', params.status);

      const response = await api.get(`/user/sim_files?${queryParams.toString()}`);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch stored data'
      };
    }
  },

  requestKenterAssociation: async (data) => {
    try {
      const response = await api.post('/kenter/request-association', {
        customer_number: data.customer_number,
        ean_code: data.ean_code
      });
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      // Handle specific error cases
      if (error.response?.status === 409) {
        return {
          success: false,
          error: 'A request for this EAN code already exists'
        };
      }
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to submit Kenter association request'
      };
    }
  },

  // Add this method to simulationService
  downloadStoredFile: async (fileId, filename) => {
    try {
      const response = await api.get(`/user/sim_files/${fileId}/download`, {
        responseType: 'blob'
      });
      
      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Use provided filename or get it from Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      const defaultFilename = contentDisposition
        ? contentDisposition.split('filename=')[1]?.replace(/"/g, '')
        : filename || 'downloaded-file.xlsx';
      
      // Create and trigger download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', defaultFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      
      return { success: true };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to download file'
      };
    }
  },

  // Add this method to simulationService
  uploadModelInstanceProfile: async (modelInstanceId, file) => {
    try {
      console.log('File to upload:', file);
      
      // Ensure we have a valid File object
      if (!(file instanceof File)) {
        throw new Error('Invalid file object provided');
      }

      // Create FormData object to send the file
      const formData = new FormData();
      
      // Append the file with just the key and file object
      formData.append('file', file);

      // Debug the FormData contents
      for (let pair of formData.entries()) {
        console.log('FormData entry:', pair[0], pair[1]);
      }

      const response = await api.post(
        `/model-instances/${modelInstanceId}/model-file-upload`,
        formData,
        {
          headers: {
            // Let the browser set the Content-Type header with boundary
            'Accept': 'application/json',
            // Prevent axios from trying to JSON stringify the FormData
            'Content-Type': 'multipart/form-data',
          },
          // Add this to prevent axios from transforming the request
          transformRequest: [(data) => data],
        }
      );

      if (!response.data) {
        throw new Error('No response data received');
      }

      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      console.error('File upload error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      return {
        success: false,
        error: error.response?.data?.error || error.message || 'Failed to upload profile file'
      };
    }
  },

  createLoadProfile: async (profileData) => {
    try {
      // Ensure the date is formatted correctly with fixed month and day
      const startDate = new Date(profileData.start_date);
      startDate.setMonth(0); // January
      startDate.setDate(1); // First day of month
      
      const formattedData = {
        ...profileData,
        start_date: startDate.toISOString().split('T')[0], // Format as YYYY-01-01
      };

      const response = await api.post('/model-instances/create-load-profile', formattedData);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to create load profile'
      };
    }
  },

  createLoadProfileForInstance: async (instanceId, profileData) => {
    try {
      // Ensure the date is formatted correctly with fixed month and day
      const startDate = new Date(profileData.start_date);
      startDate.setMonth(0); // January
      startDate.setDate(1); // First day of month
      
      const formattedData = {
        ...profileData,
        start_date: startDate.toISOString().split('T')[0], // Format as YYYY-01-01
      };

      const response = await api.post(`/model-instances/${instanceId}/create-load-profile`, formattedData);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to create load profile'
      };
    }
  }
};

export default simulationService; 