import PVAssetModule from './PVAssetModule';
import BatteryAssetModule from './BatteryAssetModule';
import LoadsAssetModule from './LoadsAssetModule';
import TariffsAssetModule from './TariffsAssetModule';
import ControllersAssetModule from './ControllersAssetModule';

const assetModules = {
  pv: PVAssetModule,
  battery: BatteryAssetModule,
  load: LoadsAssetModule,
  tariff: TariffsAssetModule,
  controller: ControllersAssetModule
};

export default assetModules; 