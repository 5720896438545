import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import AppTheme from '../../shared-theme/AppTheme';
import ColorModeIconDropdown from '../../shared-theme/ColorModeIconDropdown';
import AssetResultsCard from '../../user-interface/user-simulator/existing-simulation/components/results/AssetResultsCard';
import simulationService from '../../../services/simulationService';
import { CircularProgress, Alert, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

export default function CompareScenarios() {
  const navigate = useNavigate();
  // Fixed simulation and scenario IDs - store as numbers
  const SIMULATION_ID = 392;
  const SCENARIO_IDS = {
    first: 367,
    second: 368
  };

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [scenarios, setScenarios] = React.useState({
    first: null,
    second: null
  });

  // Fetch scenarios data
  React.useEffect(() => {
    const fetchScenarios = async () => {
      try {
        // Get all scenarios from the simulation
        const response = await simulationService.getSimulationScenarios(SIMULATION_ID);
        
        if (response.success) {
          // Convert IDs to numbers for comparison
          const firstScenario = response.data.scenarios.find(
            s => Number(s.id) === SCENARIO_IDS.first
          );
          const secondScenario = response.data.scenarios.find(
            s => Number(s.id) === SCENARIO_IDS.second
          );
          
          if (firstScenario && secondScenario) {
            setScenarios({
              first: firstScenario,
              second: secondScenario
            });
          } else {
            console.log('Available scenarios:', response.data.scenarios);
            throw new Error('Scenarios not found');
          }
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        console.error('Error fetching scenarios:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchScenarios();
  }, []);

  const handleTransitionClick = () => {
    navigate('/home'); // Navigate to user home page
  };

  if (loading) {
    return (
      <AppTheme>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </AppTheme>
    );
  }

  if (error) {
    return (
      <AppTheme>
        <Box sx={{ p: 3 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </AppTheme>
    );
  }

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Box
        sx={(theme) => ({
          width: '100%',
          minHeight: '100vh',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
          ...theme.applyStyles('dark', {
            backgroundImage:
              'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
          }),
        })}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            zIndex: 1000
          }}
        >
          <ColorModeIconDropdown />
        </Box>

        <Container maxWidth={false} sx={{ px: { xs: 2, sm: 4, md: 6 } }}>
          <Box sx={{ py: 4 }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 4
            }}>
              <Typography variant="h4" sx={{ fontWeight: 600 }}>
                Scenario Comparison
              </Typography>
              <Button
                variant="contained"
                color="info"
                size="small"
                onClick={handleTransitionClick}
                sx={{
                  minWidth: 120,
                  fontSize: '1.3rem',
                  color: '#fff',
                  mr: 8,
                  py: 1.5,
                  px: 3
                }}
              >
                Transition Now !
              </Button>
            </Box>

            <Grid container spacing={4}>
              {/* Original Scenario */}
              <Grid item xs={12} md={6}>
                <Card 
                  elevation={0}
                  sx={{ 
                    height: '100%',
                    backgroundColor: 'background.paper',
                    p: 3,
                    borderRadius: 2,
                    '& .MuiCardContent-root': {
                      px: 4
                    }
                  }}
                >
                  <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 500 }}>
                      Original Scenario
                    </Typography>
                    {scenarios.first && (
                      <AssetResultsCard
                        simulationId={String(SIMULATION_ID)}
                        scenarioId={String(SCENARIO_IDS.first)}
                        scenario={scenarios.first}
                        onSectionHeightChange={() => {}}
                        sectionHeights={{}}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>

              {/* Optimized Scenario */}
              <Grid item xs={12} md={6}>
                <Card 
                  elevation={0}
                  sx={{ 
                    height: '100%',
                    backgroundColor: `${('#004D7A')} !important`,  // Force this color in both modes
                    p: 3,
                    borderRadius: 2,
                    '& .MuiCardContent-root': {
                      px: 4
                    }
                  }}
                >
                  <CardContent>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 500 }}>
                      Optimized Scenario
                    </Typography>
                    {scenarios.second && (
                      <AssetResultsCard
                        simulationId={String(SIMULATION_ID)}
                        scenarioId={String(SCENARIO_IDS.second)}
                        scenario={scenarios.second}
                        onSectionHeightChange={() => {}}
                        sectionHeights={{}}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </AppTheme>
  );
}
