// Asset types
export const ASSET_TYPES = {
  CONTROLLER: 'controller',
  PV: 'pv',
  BATTERY: 'battery',
  TARIFF: 'tariff',
  LOAD: 'load'
};

// Fixed positions for each asset type relative to controller
export const ASSET_POSITIONS = {
  [ASSET_TYPES.CONTROLLER]: { x: 0, y: 0 }, // Center
  [ASSET_TYPES.PV]: { x: 0, y: -150 }, // Above
  [ASSET_TYPES.BATTERY]: { x: 0, y: 150 }, // Below
  [ASSET_TYPES.TARIFF]: { x: -150, y: 0 }, // Left
  [ASSET_TYPES.LOAD]: { x: 150, y: 0 }, // Right
};

// Spacing between multiple assets of the same type
export const MULTI_ASSET_SPACING = 140;

// Connection rules
export const shouldConnect = (sourceType, targetType) => {
  // All assets connect only to controller
  return sourceType === ASSET_TYPES.CONTROLLER || targetType === ASSET_TYPES.CONTROLLER;
}; 