import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Stack,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import simulationService from '../../../../../services/simulationService';
import { motion, AnimatePresence } from 'framer-motion';

const DATA_SOURCES = [
  { id: 'kenter', name: 'Kenter' },
  { id: 'fudura', name: 'Fudura' },
  { id: 'isolarcloud', name: 'ISolarCloud' }
];

const STATUS_COLORS = {
  pending: 'warning',
  completed: 'success',
  failed: 'error',
  processing: 'info'
};

export default function RetrieveDataDialog({ open, onClose }) {
  const [dataSource, setDataSource] = React.useState('');
  const [eanCode, setEanCode] = React.useState('');
  const [customerNumber, setCustomerNumber] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [retrievedData, setRetrievedData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(null);

  React.useEffect(() => {
    if (open) {
      fetchStoredData();
    }
  }, [open]);

  const fetchStoredData = async () => {
    try {
      setIsLoading(true);
      const response = await simulationService.getUserStoredData({
        // Remove filters to show all data
      });

      if (response.success) {
        setRetrievedData(response.data.files.map(file => ({
          id: file.id,
          name: `${file.source} Data - ${file.meta?.ean_code || file.filename || 'N/A'}`,
          type: file.source,
          status: file.status,
          download_url: file.download_url,
          filename: file.filename || `${file.source}-data-${file.meta?.ean_code || 'unknown'}.xlsx`
        })));
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.error('Failed to fetch stored data:', error);
      setStatusMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};
    
    if (!eanCode) {
      errors.eanCode = 'EAN Code is required';
    } else if (!/^\d{18}$/.test(eanCode)) {
      errors.eanCode = 'EAN Code must be exactly 18 digits';
    }

    if (!customerNumber) {
      errors.customerNumber = 'Customer Number is required';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleRetrieveData = async () => {
    try {
      if (dataSource === 'kenter') {
        if (!validateForm()) {
          return;
        }

        setIsSubmitting(true);
        const response = await simulationService.requestKenterAssociation({
          customer_number: customerNumber,
          ean_code: eanCode
        });

        if (response.success) {
          setStatusMessage('Data retrieval request submitted successfully');
          setCustomerNumber('');
          setEanCode('');
          await fetchStoredData();
        } else {
          throw new Error(response.error);
        }
      }
      // Add other data source handlers here
    } catch (error) {
      console.error('Failed to retrieve data:', error);
      setStatusMessage(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDownload = async (fileId, downloadUrl, filename) => {
    try {
      setIsDownloading(fileId);
      
      // For direct download URLs (pre-signed URLs)
      if (downloadUrl) {
        const response = await fetch(downloadUrl);
        
        if (!response.ok) {
          throw new Error('Download failed');
        }
        
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        // Fallback to the service method if no direct URL is available
        const response = await simulationService.downloadStoredFile(fileId, filename);
        if (!response.success) {
          throw new Error(response.error);
        }
      }
    } catch (error) {
      console.error('Failed to download data:', error);
      setStatusMessage(`Error downloading data: ${error.message}`);
    } finally {
      setIsDownloading(null);
    }
  };

  return (
    <AnimatePresence>
      {open && (
        <Dialog 
          open={open} 
          onClose={onClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            component: motion.div,
            initial: { y: -20, opacity: 0 },
            animate: { y: 0, opacity: 1 },
            exit: { y: -20, opacity: 0 },
            transition: { duration: 0.2 }
          }}
        >
          <DialogTitle sx={{ 
            m: 0, 
            p: 2, 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography component="div" variant="h6">
              Retrieve External Data
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ color: 'grey.500' }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Stack spacing={3}>
              {statusMessage && (
                <Typography 
                  color={statusMessage.includes('Error') ? 'error' : 'success'}
                  sx={{ mt: 1 }}
                >
                  {statusMessage}
                </Typography>
              )}

              <FormControl fullWidth>
                <InputLabel>Data Source</InputLabel>
                <Select
                  value={dataSource}
                  onChange={(e) => setDataSource(e.target.value)}
                  label="Data Source"
                >
                  {DATA_SOURCES.map((source) => (
                    <MenuItem key={source.id} value={source.id}>
                      {source.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {dataSource === 'kenter' && (
                <Stack spacing={2}>
                  <TextField
                    label="EAN Code"
                    fullWidth
                    value={eanCode}
                    onChange={(e) => {
                      setEanCode(e.target.value);
                      setValidationErrors(prev => ({ ...prev, eanCode: null }));
                    }}
                    helperText={validationErrors.eanCode || "Must be 18 digits"}
                    error={!!validationErrors.eanCode}
                    disabled={isSubmitting}
                  />
                  <TextField
                    label="Customer Number"
                    fullWidth
                    value={customerNumber}
                    onChange={(e) => {
                      setCustomerNumber(e.target.value);
                      setValidationErrors(prev => ({ ...prev, customerNumber: null }));
                    }}
                    helperText={validationErrors.customerNumber}
                    error={!!validationErrors.customerNumber}
                    disabled={isSubmitting}
                  />
                  <Button
                    variant="contained"
                    onClick={handleRetrieveData}
                    fullWidth
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" sx={{ mx: 1 }} />
                    ) : (
                      'Retrieve Now'
                    )}
                  </Button>
                </Stack>
              )}

              <Divider sx={{ my: 2 }} />

              <TableContainer component={Paper} variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <CircularProgress size={24} sx={{ my: 1 }} />
                        </TableCell>
                      </TableRow>
                    ) : retrievedData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    ) : (
                      retrievedData.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.type}</TableCell>
                          <TableCell>
                            <Chip 
                              label={row.status.toUpperCase()}
                              color={STATUS_COLORS[row.status.toLowerCase()]}
                              size="small"
                            />
                          </TableCell>
                          <TableCell align="right">
                            {row.status === 'completed' && (
                              <IconButton
                                onClick={() => handleDownload(row.id, row.download_url, row.filename)}
                                size="small"
                                color="primary"
                                disabled={isDownloading === row.id}
                              >
                                {isDownloading === row.id ? (
                                  <CircularProgress size={20} color="inherit" />
                                ) : (
                                  <DownloadIcon />
                                )}
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </AnimatePresence>
  );
}

RetrieveDataDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
