// This component handles the data structure and transformation logic.
// It serves as a centralized service for managing and 
// transforming assets between the backend and frontend. 

import { getModelInstances } from '../../../../services/api';
import { ASSET_CLASSES } from './AssetCategories';

class AssetLibrary {
  constructor() {
    this.assets = {
      [ASSET_CLASSES.PV]: [],
      [ASSET_CLASSES.STORAGE]: [],
      [ASSET_CLASSES.LOAD]: [],
      [ASSET_CLASSES.CONTROLLER]: [],
      [ASSET_CLASSES.TARIFF]: []
    };
    this.initialized = false;
    this.initializationPromise = null;
    this.selectedCategory = null;
  }

  transformModelInstance(instance) {
    if (!instance.type) {
      return null;
    }

    const baseAsset = {
      id: instance.id.toString(),
      name: instance.name,
      type: instance.type
    };

    // Handle controller types with exact matching
    if (instance.type === 'controller_base') {
      return {
        ...baseAsset,
        specs: {
          'Type': 'Base Controller',
          'Battery SOC Range': `${instance.battery_soc_min}% - ${instance.battery_soc_max}%`,
          'Battery Max Power': instance.battery_max_p === Infinity ? 'Unlimited' : `${instance.battery_max_p} W`
        },
        params: { ...instance }
      };
    }

    if (instance.type === 'controller_grid') {
      return {
        ...baseAsset,
        specs: {
          'Type': 'Grid Controller',
          'Battery SOC Range': `${instance.battery_soc_min}% - ${instance.battery_soc_max}%`,
          'Battery Max Power': instance.battery_max_p === Infinity ? 'Unlimited' : `${instance.battery_max_p} W`,
          'Import Limit': `${instance.import_limit} W`,
          'Export Limit': `${instance.export_limit} W`
        },
        params: { ...instance }
      };
    }

    if (instance.type === 'controller_pvcurtailment') {
      return {
        ...baseAsset,
        specs: {
          'Type': 'PV Curtailment Controller',
          'Battery SOC Range': `${instance.battery_soc_min}% - ${instance.battery_soc_max}%`,
          'Battery Max Power': instance.battery_max_p === Infinity ? 'Unlimited' : `${instance.battery_max_p} W`,
          'PV Setpoint': `${instance.setpoint}%`
        },
        params: { ...instance }
      };
    }

    // Handle PV types
    if (instance.type === 'pv_weather') {
      return {
        ...baseAsset,
        specs: {
          Type: 'Weather-based PV',
          'Nominal Power': `${instance.nominal_power} W`,
          'Number of Panels': instance.num_panels.toString(),
          'Efficiency': `${instance.efficiency * 100}%`,
          'Capital Cost': `${instance.capital_cost} €`,
          'O&M Cost': `${instance.om_cost} €`
        },
        params: { ...instance }
      };
    }

    if (instance.type === 'pv_profile') {
      return {
        ...baseAsset,
        specs: {
          Type: 'Profile-based PV',
          'Factor': instance.factor.toString(),
          'Capital Cost': `${instance.capital_cost} €`,
          'O&M Cost': `${instance.om_cost} €`
        },
        params: { ...instance }
      };
    }

    // Handle battery
    if (instance.type === 'battery') {
      return {
        ...baseAsset,
        specs: {
          'Max Energy': `${instance.max_energy} Wh`,
          'Max Power': `${instance.max_p} W`,
          'Min Power': `${instance.min_p} W`,
          'Initial SOC': `${instance.initial_soc}%`,
          'SOC Range': `${instance.soc_min}% - ${instance.soc_max}%`,
          'Efficiency': `${instance.charge_efficiency * 100}% / ${instance.discharge_efficiency * 100}%`,
          'Capital Cost': `${instance.capital_cost} €`,
          'O&M Cost': `${instance.om_cost} €/year`,
          'Output Type': instance.output_type,
          'Data File': instance.data_file || 'None'
        },
        params: { ...instance }
      };
    }

    // Handle load
    if (instance.type === 'load') {
      return {
        ...baseAsset,
        specs: {
          'Type': 'Load Profile',
          'Factor': instance.factor.toString(),
          'Output Type': instance.output_type
        },
        params: { ...instance }
      };
    }

    // Handle tariff types
    if (instance.type === 'tariff_fixed') {
      return {
        ...baseAsset,
        specs: {
          'Type': 'Fixed Tariff',
          'Transport Cost': `${instance.transport_cost} €/kWh`,
          'Import Fixed Rate': `${instance.import_fixed_rate} €/kWh`,
          'Export Fixed Rate': instance.export_fixed_rate ? `${instance.export_fixed_rate} €/kWh` : 'Not set',
          'Data File': instance.data_file || 'None'
        },
        params: { ...instance }
      };
    }

    if (instance.type === 'tariff_variable') {
      return {
        ...baseAsset,
        specs: {
          'Type': 'Variable Tariff',
          'Transport Cost': `${instance.transport_cost} €/kWh`,
          'Import Peak Rate': `${instance.import_peak_rate} €/kWh`,
          'Import Off-Peak Rate': `${instance.import_off_peak_rate} €/kWh`,
          'Export Peak Rate': instance.export_peak_rate ? `${instance.export_peak_rate} €/kWh` : 'Not set',
          'Export Off-Peak Rate': instance.export_off_peak_rate ? `${instance.export_off_peak_rate} €/kWh` : 'Not set',
          'Peak Start Hour': `${instance.peak_start_hour}:00`,
          'Peak End Hour': `${instance.peak_end_hour}:00`,
          'Data File': instance.data_file || 'None'
        },
        params: { ...instance }
      };
    }

    if (instance.type === 'tariff_dynamic') {
      return {
        ...baseAsset,
        specs: {
          'Type': 'Dynamic Tariff',
          'Transport Cost': `${instance.transport_cost} €/kWh`,
          'Import Margin': `${instance.import_margin} €/kWh`,
          'Export Margin': instance.export_margin ? `${instance.export_margin} €/kWh` : 'Not set',
          'Data File': instance.data_file || 'None'
        },
        params: { ...instance }
      };
    }

    // Return the base asset if no specific transformation is needed
    return {
      ...baseAsset,
      specs: Object.entries(instance).reduce((acc, [key, value]) => {
        acc[key] = value?.toString() || '';
        return acc;
      }, {}),
      params: { ...instance }
    };
  }

  async initialize() {
    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = (async () => {
      const response = await getModelInstances(this.selectedCategory);
      
      // Reset the assets for this category
      this.assets[this.selectedCategory] = [];

      if (response.model_instances) {
        // Handle categories with subcategories
        if ([ASSET_CLASSES.CONTROLLER, ASSET_CLASSES.PV, ASSET_CLASSES.TARIFF]
          .includes(this.selectedCategory)) {
          Object.values(response.model_instances).forEach(instances => {
            const transformedInstances = instances.map(instance => 
              this.transformModelInstance(instance)
            ).filter(Boolean); // Filter out any null values

            if (transformedInstances.length > 0) {
              this.assets[this.selectedCategory].push(...transformedInstances);
            }
          });
        } 
        else {
          // Handle other categories
          const instances = Array.isArray(response.model_instances)
            ? response.model_instances
            : Object.values(response.model_instances).flat();

          this.assets[this.selectedCategory] = instances.map(instance =>
            this.transformModelInstance(instance)
          );
        }
      }

      this.initialized = true;
      return this.assets;
    })();

    return this.initializationPromise;
  }

  setSelectedCategory(category) {
    this.selectedCategory = category;
    // Re-initialize with new category
    this.initialized = false;
    this.initializationPromise = null;
    return this.initialize();
  }

  async getAssets() {
    await this.initialize();
    return this.assets;
  }
}

const assetLibrary = new AssetLibrary();
export default assetLibrary; 