import * as React from 'react';
import { TextField, Stack, InputAdornment } from '@mui/material';
import SOCSlider from './sliders/SOCSlider';

export default class BatteryAssetModule {
  static parameters = [
    { name: 'initial_soc', label: 'Initial SOC', unit: '%', type: 'number', required: false, defaultValue: 50 },
    { name: 'max_p', label: 'Maximum Power', unit: 'kW', type: 'number', required: true },
    { name: 'min_p', label: 'Minimum Power', unit: 'kW', type: 'number', required: true },
    { name: 'max_energy', label: 'Maximum Energy', unit: 'kWh', type: 'number', required: true },
    { name: 'charge_efficiency', label: 'Charge Efficiency', unit: '', type: 'number', required: true },
    { name: 'discharge_efficiency', label: 'Discharge Efficiency', unit: '', type: 'number', required: true },
    { name: 'soc_min', label: 'Minimum SOC', unit: '%', type: 'number', required: true },
    { name: 'soc_max', label: 'Maximum SOC', unit: '%', type: 'number', required: true },
    { name: 'capital_cost', label: 'Capital Cost', unit: '€', type: 'number', required: false, defaultValue: 0 },
    { name: 'om_cost', label: 'O&M Cost', unit: '€/year', type: 'number', required: false, defaultValue: 0 }
  ];

  static validate(data) {
    const errors = {};
    if (data.initial_soc && (data.initial_soc < 0 || data.initial_soc > 100)) {
      errors.initial_soc = 'Initial SOC must be between 0 and 100';
    }
    if (!data.max_p || data.max_p <= 0) {
      errors.max_p = 'Maximum power must be greater than 0';
    }
    if (!data.min_p || data.min_p > 0) {
      errors.min_p = 'Minimum power must be less than or equal to 0';
    }
    if (!data.max_energy || data.max_energy <= 0) {
      errors.max_energy = 'Maximum energy must be greater than 0';
    }
    if (!data.charge_efficiency || data.charge_efficiency <= 0 || data.charge_efficiency > 1) {
      errors.charge_efficiency = 'Charge efficiency must be between 0 and 1';
    }
    if (!data.discharge_efficiency || data.discharge_efficiency <= 0 || data.discharge_efficiency > 1) {
      errors.discharge_efficiency = 'Discharge efficiency must be between 0 and 1';
    }
    if (!data.soc_min || data.soc_min < 0 || data.soc_min > 100) {
      errors.soc_min = 'Minimum SOC must be between 0 and 100';
    }
    if (!data.soc_max || data.soc_max < 0 || data.soc_max > 100) {
      errors.soc_max = 'Maximum SOC must be between 0 and 100';
    }
    if (data.soc_min >= data.soc_max) {
      errors.soc_min = 'Minimum SOC must be less than Maximum SOC';
      errors.soc_max = 'Maximum SOC must be greater than Minimum SOC';
    }
    if (data.capital_cost && data.capital_cost < 0) {
      errors.capital_cost = 'Capital cost must be greater than or equal to 0';
    }
    if (data.om_cost && data.om_cost < 0) {
      errors.om_cost = 'O&M cost must be greater than or equal to 0';
    }
    return errors;
  }

  static transformForAPI(data) {
    return {
      type: 'battery',
      initial_soc: data.initial_soc ? parseFloat(data.initial_soc) : 50,
      max_p: parseFloat(data.max_p),
      min_p: parseFloat(data.min_p),
      max_energy: parseFloat(data.max_energy),
      charge_efficiency: parseFloat(data.charge_efficiency),
      discharge_efficiency: parseFloat(data.discharge_efficiency),
      soc_min: parseFloat(data.soc_min || 0),
      soc_max: parseFloat(data.soc_max || 100),
      capital_cost: data.capital_cost ? parseFloat(data.capital_cost) : 0,
      om_cost: data.om_cost ? parseFloat(data.om_cost) : 0
    };
  }

  static renderFields({ data, onChange, errors }) {
    const getNumericValue = (value) => {
      if (!value && value !== 0) {
        return '';
      }
      const numValue = parseFloat(value);
      return isNaN(numValue) ? '' : numValue;
    };

    const handleSocChange = (min, max) => {
      onChange('soc_min', min);
      onChange('soc_max', max);
    };

    return (
      <Stack spacing={2}>
        <SOCSlider
          minSoc={data.soc_min}
          maxSoc={data.soc_max}
          onChange={handleSocChange}
          label="SOC Limits"
        />
        
        {this.parameters
          .filter(param => !['soc_min', 'soc_max'].includes(param.name))
          .map((param) => (
            <TextField
              key={param.name}
              label={param.label}
              type="number"
              value={getNumericValue(data[param.name])}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue === '' || (!isNaN(newValue) && (param.name === 'min_p' || parseFloat(newValue) >= 0))) {
                  onChange(param.name, newValue === '' ? undefined : newValue);
                }
              }}
              error={!!errors?.[param.name]}
              helperText={errors?.[param.name] || (param.defaultValue !== undefined && !data[param.name] ? 
                `Default: ${param.defaultValue}` : undefined)}
              inputProps={{
                min: param.name === 'min_p' ? undefined : 0,
                step: "any"
              }}
              InputProps={{
                endAdornment: param.unit && (
                  <InputAdornment position="end">
                    {param.unit}
                  </InputAdornment>
                )
              }}
              required={param.required}
            />
          ))}
      </Stack>
    );
  }
} 