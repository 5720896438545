// This widget is used to display the timeseries chart for the simulation.

import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chart from 'react-apexcharts';
import simulationService from '../../../../../../../services/simulationService';

const WidgetTimeseries = ({ simulationId, scenarioId, modelInstanceId, title, metricKey }) => {
  const theme = useTheme();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await simulationService.getAssetHourlyProfile(
          simulationId, 
          scenarioId, 
          modelInstanceId,
          metricKey
        );

        // Check if we have valid data structure
        if (response?.success && response?.data?.series) {
          setData({
            series: response.data.series
          });
        } else {
          console.error('Invalid response structure:', response);
          throw new Error('Invalid data format received from server');
        }
      } catch (error) {
        console.error('Failed to fetch timeseries data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [simulationId, scenarioId, modelInstanceId, metricKey]);

  // Memoize series data
  const series = React.useMemo(() => [{
    name: title,
    data: data?.series || []
  }], [data?.series, title]);

  // Memoize chart options
  const chartOptions = React.useMemo(() => ({
    chart: {
      type: 'area',
      height: 300,
      toolbar: {
        show: false
      },
      background: 'transparent',
      animations: {
        enabled: false
      }
    },
    stroke: {
      curve: 'straight',  // Changed from 'smooth' to 'straight' for better performance
      width: 2
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100]
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: theme.palette.text.secondary
        },
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM yyyy',
          day: 'dd MMM',
          hour: 'HH:mm'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.text.secondary
        },
        formatter: (value) => value.toFixed(2)
      },
      min: 0,
      max: data?.series?.length 
        ? Math.ceil(Math.max(...data.series.map(point => point.y)))
        : 100
    },
    tooltip: {
      theme: theme.palette.mode,
      x: {
        format: 'dd MMM yyyy HH:mm'
      },
      y: {
        formatter: (value) => value.toFixed(3)
      },
      style: {
        fontSize: '12px',
        fontFamily: theme.typography.fontFamily
      },
      background: {
        enabled: true,
        foreColor: theme.palette.text.primary,
        borderColor: theme.palette.divider,
      }
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true
        }
      },
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    colors: [theme.palette.primary.main]
  }), [theme.palette.mode, theme.palette.text.secondary, theme.palette.divider, theme.palette.primary.main, 
       theme.typography.fontFamily, theme.palette.text.primary, data?.series]);

  // Memoize chart component
  const ChartComponent = React.useMemo(() => (
    <Chart
      options={chartOptions}
      series={series}
      type="area"
      height={300}
    />
  ), [chartOptions, series]);

  if (loading) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="text.secondary">Loading data...</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="error">Error: {error}</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (!data?.series?.length) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="text.secondary">No data available</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ height: 300, width: '100%' }}>
          {ChartComponent}
        </Box>
      </CardContent>
    </Card>
  );
};

WidgetTimeseries.propTypes = {
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modelInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  metricKey: PropTypes.string.isRequired
};

export default WidgetTimeseries; 