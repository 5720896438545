// This widget is used to display the candlestick chart for the simulation.

import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chart from 'react-apexcharts';
import simulationService from '../../../../../../../services/simulationService';
import dayjs from 'dayjs';

const WidgetCandlestick = ({ simulationId, scenarioId, modelInstanceId, title, metricKey }) => {
  const theme = useTheme();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await simulationService.getAssetCandlestick(simulationId, scenarioId, modelInstanceId, metricKey);
        if (response.success) {
          setData(response.data);
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        console.error('Failed to fetch candlestick data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [simulationId, scenarioId, modelInstanceId, metricKey]);

  // Memoize series data
  const series = React.useMemo(() => [{
    type: 'boxPlot',
    data: data?.values?.map(item => ({
      x: dayjs(item.timestamp).format('MMM YYYY'),
      y: [item.min, item.q1, item.median, item.q3, item.max]
    })) || []
  }], [data?.values]);

  // Memoize chart options with safe null checks
  const chartOptions = React.useMemo(() => ({
    chart: {
      type: 'boxPlot',
      height: 300,
      toolbar: {
        show: false
      },
      background: 'transparent',
      animations: {
        enabled: false
      },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: theme.palette.primary.light,
          lower: theme.palette.primary.dark
        }
      }
    },
    xaxis: {
      type: 'category',
      labels: {
        style: {
          colors: theme.palette.text.secondary,
          fontSize: '12px'
        },
        rotateAlways: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.text.secondary,
          fontSize: '12px'
        },
        formatter: (value) => value.toFixed(2)
      }
    },
    tooltip: {
      theme: theme.palette.mode,
      custom: function({ seriesIndex, dataPointIndex, w }) {
        const data = w.config.series[seriesIndex]?.data[dataPointIndex];
        if (!data) return '';
        
        return `
          <div style="
            padding: 12px;
            background: ${theme.palette.background.paper};
            border: 1px solid ${theme.palette.divider};
            border-radius: ${theme.shape.borderRadius}px;
            box-shadow: ${theme.shadows[3]};
            font-size: 12px;
            font-family: ${theme.typography.fontFamily};
            color: ${theme.palette.text.primary};
          ">
            <div style="font-weight: 500; margin-bottom: 8px;">${data.x}</div>
            <div style="display: flex; flex-direction: column; gap: 4px;">
              <div>Maximum: ${data.y[4]?.toFixed(3) ?? 'N/A'}</div>
              <div>Q3 (75th): ${data.y[3]?.toFixed(3) ?? 'N/A'}</div>
              <div>Median: ${data.y[2]?.toFixed(3) ?? 'N/A'}</div>
              <div>Q1 (25th): ${data.y[1]?.toFixed(3) ?? 'N/A'}</div>
              <div>Minimum: ${data.y[0]?.toFixed(3) ?? 'N/A'}</div>
            </div>
          </div>
        `;
      }
    },
    grid: {
      borderColor: theme.palette.divider,
      padding: {
        right: 20
      },
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    theme: {
      mode: theme.palette.mode
    }
  }), [theme]);

  // Memoize chart component
  const ChartComponent = React.useMemo(() => (
    <Chart
      options={chartOptions}
      series={series}
      type="boxPlot"
      height={300}
    />
  ), [chartOptions, series]);

  if (loading) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="text.secondary">Loading data...</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="error">Error: {error}</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (!data?.values?.length) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="text.secondary">No data available</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ height: 300, width: '100%' }}>
          {ChartComponent}
        </Box>
      </CardContent>
    </Card>
  );
};

WidgetCandlestick.propTypes = {
  simulationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scenarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  modelInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  metricKey: PropTypes.string.isRequired
};

export default WidgetCandlestick; 