// MarketingPage component, serves as the main component of the marketing page
// It tells how the marketing page should be displayed and includes all the sections of the page.

import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Vision from './components/Vision'; 
// import Highlights from './components/Highlights';
// import Pricing from './components/Pricing';
import Features from './components/Features';
// import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import AppTheme from '../shared-theme/AppTheme';
import { Global, css } from '@emotion/react'; // You may need to install @emotion/react if not already installed
import Team from './components/Team';

export default function MarketingPage(props) {
  return (
    <AppTheme {...props}>
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
          }
        `}
      />
      <CssBaseline enableColorScheme />
      <AppAppBar />
      <Hero />
      <div>
        <LogoCollection />
        <Vision />
        <Divider />
        <Features />
        <Divider />
        {/* <Testimonials />
        <Divider />
        <Highlights />
        <Divider /> */}
        {/* <Pricing />
        <Divider /> */}
        <Team />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </div>
    </AppTheme>
  );
}
